import React, {useState, useMemo, useEffect} from "react";
import axios from "axios";
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";
import { Tabs, Tab } from 'react-bootstrap';

//each index corresponds to the score of the question. Initialized at 0.
const current = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
const baseUrl = process.env.REACT_APP_baseUrl;
const baseUrlAWS =`${baseUrl}api/myprogress`

function AppearanceTest() {

  const [submittedToday, setSubmittedToday] = useState(true)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === 'appearance').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'appearance')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)

                if (testDate === currDate) {
                  setSubmittedToday(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

  //used to calculate total value
  const [total, setTotal] = useState(0);  

  //array of questions
  const questions = ["1. I am often concerned about how my body looks.", "2. I have many faults when it comes to my physical appearance.",
  "3. I feel satisfied with my body as it is.",  "4. I wish I had a different body.", "5. I feel comfortable with my body.",
 "6. I often feel ashamed of my body.", "7. I feel embarrassed when others see my body.", "8. I worry about my appearance.", 
 "9. I like what I see when I look in the mirror.", "10. I often think about changing my body.", "11. I feel good about my physical abilities.", "12. I am pleased with my body overall.",
 "13. I think I am attractive.", "14. I like the way my body looks.", "15. I often compare my appearance to others.", 
 "16. I think my body is well-proportioned.", "17. I enjoy looking at myself in the mirror.", "18. I am dissatisfied with my physical appearance.",
 "19. I am content with my body weight.", "20. I feel frustrated with my body.", "21. I feel confident about my appearance.", 
 "22. I worry about gaining weight.", "23. I am relaxed about how I look."]

 //calculate total. Add up all the numbers in the array and divide by 28. Round to 1 decimal place
  const calculateTotal = async () => {
    let n = 0; 
    let unansweredQ = false
    console.log(current); 
    for (let i = 0; i<current.length; i++) {
        n+= current[i]; 
        if (current[i] === 0) {
          unansweredQ = true
        }
    }

    if (unansweredQ) {
      return
    }

    setTotal(n); 

    let testRes = {}
    testRes["user"] = userId
    testRes["testId"] = "appearance"
    testRes["score"] = n

    submitData(testRes)
    console.log(testRes)
  }

  //updates the array by updating the element at given index
  const handleAgree = (index, num) => {
      current[index.index] = num;

  }

  // call API to submit the form data 
  const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
  }

   //I used BootStrap CSS so the classNames won't work unless it is installed and imported in index.js 

  return (
    <div className="App" style={{marginTop: "65px"}}>
      <Tabs defaultActiveKey="general" className="mb-3">
        <Tab eventKey="general" title="General Information">
          {/* Move your General Information content here */}
          <div>General Information Content</div>
        </Tab>
        <Tab eventKey="tests" title="Tests">
          <div className="container-fluid m-4">
            <div className="row">
              <div className="col-5">
                <h5><strong>Questions</strong></h5>
              </div>
              <div className="col smallfont d-flex justify-content-center">
                <p>Strongly Disagree</p>
              </div>
              <div className="col smallfont d-flex justify-content-center">
                <p>Disagree</p>
              </div>
              <div className="col smallfont d-flex justify-content-center">
                <p>Slightly Disagree</p>
              </div>
              <div className="col smallfont d-flex justify-content-center">
                <p>Neutral</p>
              </div>
              <div className="col smallfont d-flex justify-content-center">
                <p>Slightly Agree</p>
              </div>
              <div className="col smallfont d-flex justify-content-center">
                <p>Agree</p>
              </div>
              <div className="col smallfont d-flex justify-content-center">
                <p>Strongly Agree</p>
              </div>
            </div>
            {questions.map((item, index) => {
              const score = [1,2,3,4,5,6,7]
              if (index === 0 || index === 1 || index === 3 || index === 5 || index === 6 || index === 7
                 || index === 9 || index === 14 || index === 17 || index === 19 || index === 21) {
                  score.reverse(); //taking into account the scores that need to be put into reverse. Will temporary reverse the array for given question
              }
              return <div className ="row d-flex align-items-center" key = {index}><div className="col-5 mt-2"><p className = "h6" key = {index}>{item}</p></div>
                <div className="col d-flex justify-content-center mt-1">
                  <input type = "radio" name = {index} id ="stronglydisagree" onClick = {() => handleAgree({index}, score[0])}/>        
                </div>
                <div className="col d-flex justify-content-center mt-1">
                  <input type = "radio" name = {index} id ="moderatelydisagree" onChange = {() => handleAgree({index}, score[1])}/>
                </div>
                <div className="col d-flex justify-content-center mt-1">
                  <input type = "radio" name = {index} id ="slightlydisagree" onChange = {() => handleAgree({index}, score[2])}/>
                </div>
                <div className="col d-flex justify-content-center mt-1">
                  <input type = "radio" name = {index} id ="nuetral" onChange = {() => handleAgree({index}, score[3])}/>
                </div>
                <div className="col d-flex justify-content-center mt-1">
                  <input type = "radio" name = {index} id ="slightlyagree" onChange = {() => handleAgree({index}, score[4])}/>
                </div>
                <div className="col d-flex justify-content-center mt-1">
                  <input type = "radio" name = {index} id ="moderatelyagree" onChange = {() => handleAgree({index}, score[5])}/>
                </div>
                <div className="col d-flex justify-content-center mt-1">
                  <input type = "radio" name = {index} id ="stronglyagree" onChange = {() => handleAgree({index}, score[6])}/>
                </div>
                </div>
            })}
            <div className="row mt-4">
              <div className="col d-flex flex-column align-items-center text-center ">
                {(!submittedToday && !total) && (
                  <button className="btn btn-primary" onClick = {() => calculateTotal()}>Calculate Score</button>
                )}
                {(submittedToday || total) && (
                  <button className="btn btn-primary">Already Submitted Today</button>
                )}
                <div>{total ? <h3>Your score: {total}</h3> : <div></div>}</div>
                </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default AppearanceTest;
