import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import AboutMe from "./Sections/AboutMe";
import Diet from "./Sections/Diet";
import ExerciseSleepStress from "./Sections/ExerciseSleepStress";
import Supplements from "./Sections/Supplements";
import CalculatorResponse from "../../components/CalculatorResponse";
import { useAuthContext } from "../../hooks/useAuthContext";
import {
  fetchProbData,
  fetchUserInfo,
  updateProbData,
  getScoreFromLocal,
  fetchDBData,
  fetchUserData,
} from "./actions";
import { getLifestyleResponseData } from "../FullProfile/actions";
import {DATE_OF_BIRTH, GENDER, CIGARETTES_USE, AGE,
  FORMER_CIGARETTES_YEARS, CURRENT_CIGARETTES_YEARS, ALCOHOL_DRINKS_PER_WEEK,
  calorieRestrictionName, transFatIntakeName, refinedGrainName, wholeGrainName,
  legumesName, processedMeatName, poultryName, redMeatName, vegetablesName,
  fruitName, waterIntakeName, sugarySugar, artificiallySugar,
  cardioName, strengthTrainingName, actualSleepHoursName, sleepQualityName,
  activitiesSaunaBathingName, stressQualityName, calciumName, fishOilOmega3Name,
  matchaGreenTeaName, smoking_frequencyName, fruitAndVegetableName} from "./archive/CalculatorName";

import "./Calculator.css";

const CALCULATOR_TIMES = 3;

function Calculator() {
  const { user, dispatch } = useAuthContext();
  const aboutMeAlcoholModelName = "alcohol";
  const aboutMeAgeModelName = "age";
  const aboutMeGenderModelName = "gender";
  const aboutMeSmokingStatusModelName = "smoking_status";
  const aboutMeSmokingFrequencyModelName = "smoking_frequency";
  const aboutMeSmokingDurationModelName = "smoking_duration";
  const dietCalorieRestrictionModelName = "calorie_restriction";
  const dietFatTransModelName = "fat_trans";
  const dietGrainRefinedModelName = "grain_refined";
  const dietGrainUnrefinedModelName = "grain_unrefined";
  const dietLegumesModelName = "legumes";
  const dietMeatProcessedModelName = "meat_processed";
  const dietMeatUnprocessedModelName = "meat_unprocessed";
  const dietMeatPoultryModelName = "meat_poultry";
  const dietFruitsAndVeggiesModelName = "fruits_and_veggies";
  const dietWaterModelName = "water";
  const dietRefinedSugarModelName = "refined_sugar";
  const dietArtificialSweetenerModelName = "artificial_sweetener";
  const exerciseCardioModelName = "cardio";
  const exerciseStrengthTrainingModelName = "strength_training";
  const mySleepSleepDurationModelName = "sleep_duration";
  const mySleepSleepQualityModelName = "sleep_quality_score_lifestyle";
  const exerciseSaunaFrequencyModelName = "sauna_frequency";
  const myStressStressQualityModelName = "stress_level_score";
  const nodeStressQualityModelName = "stress_level_score";
  const supplementsCalciumModelName = "calcium";
  const supplementsFishOilOmega3ModelName = "fish_oil_omega_3";
  const supplementsGreenTeaModelName = "green_tea";
  const smoking_frequencyModelName = "smokingfrequencyLable";
  const genderOptions = ["Male", "Female"];
  const cigarettesOptions = [
    "Never used",
    "Former user",
    "Current user",
    "I decline to answer",
    "I don't know",
  ];
  const calorieIntakeOptions = ["No", "Yes"];
  const sweetTimeOptions = ["Day"];
  const sleepQualityOptions = [
    "Poor - I frequently wake up during the night and do not feel rested in the morning.",
    "Fair - I occasionally wake up during the night but generally feel somewhat rested in the morning.",
    "Excellent - I sleep through the night without interruptions and wake up feeling refreshed.",
  ];
  const stressQualityOptions = [
    "Low - I rarely feel stressed and can manage daily challenges with ease.",
    "Moderate - I experience stress occasionally but can generally cope with it.",
    "High - I frequently feel overwhelmed by stress and find it difficult to cope.",
  ];

  const calciumOptions = ["mg/day", "g/day"];
  const fishOilOmega3Options = ["g/day", "mg/day"];
  const matchaGreenTeaOptions = ["ml/day", "oz/day"];
  const [aboutMeModel, setAboutMeModel] = useState({
    [DATE_OF_BIRTH]: null,
    [AGE]: null,
    [ALCOHOL_DRINKS_PER_WEEK]: null,
    [GENDER]: null,
    [CIGARETTES_USE]: null,
    [FORMER_CIGARETTES_YEARS]: null,
    [CURRENT_CIGARETTES_YEARS]: null,
    [smoking_frequencyName]: null,
    smoking_status: null,
    // [DATE_OF_BIRTH]: "2000-01-01",
    // [aboutMeAgeModelName]: 0,
    // [aboutMeAlcoholModelName]: 0,
    // [aboutMeGenderModelName]: 0,
    // [aboutMeSmokingStatusModelName]: 0,
    // [aboutMeSmokingFrequencyModelName]: 0,
    // smoking_status: 0,
    // smoking_frequency: 0,
  });
  const [dietModel, setDietModel] = useState({
    [dietArtificialSweetenerModelName]: 0,
    [dietCalorieRestrictionModelName]: 0,
    [dietFatTransModelName]: 0,
    [dietFruitsAndVeggiesModelName]: 0,
    [dietGrainRefinedModelName]: 0,
    [dietGrainUnrefinedModelName]: 0,
    [dietLegumesModelName]: 0,
    [dietMeatProcessedModelName]: 0,
    [dietMeatUnprocessedModelName]: 0,
    [dietMeatPoultryModelName]: 0,
    [dietRefinedSugarModelName]: 0,
    [dietWaterModelName]: 0,
  });
  const [exerciseSleepStressModel, setExerciseSleepStressModel] = useState({
    [exerciseSaunaFrequencyModelName]: 0,
    [exerciseStrengthTrainingModelName]: 0,
    [nodeStressQualityModelName]: 0,
    [exerciseCardioModelName]: 0,
    [mySleepSleepDurationModelName]: 0,
    [mySleepSleepQualityModelName]: 0,
  });
  const [supplementsModel, setSupplementsModel] = useState({
    [supplementsCalciumModelName]: 0,
    [supplementsFishOilOmega3ModelName]: 0,
    [supplementsGreenTeaModelName]: 0,
  });
  const [aboutMe, setAboutMe] = useState({
    [DATE_OF_BIRTH]: null,
    [AGE]: null,
    [ALCOHOL_DRINKS_PER_WEEK]: null,
    [GENDER]: null,
    [CIGARETTES_USE]: null,
    [FORMER_CIGARETTES_YEARS]: null,
    [CURRENT_CIGARETTES_YEARS]: null,
    [smoking_frequencyName]: null,
    smoking_status: null,
  });
  const [diet, setDiet] = useState({
    [artificiallySugar]: null,
    [calorieRestrictionName]: null,
    [transFatIntakeName]: null,
    [fruitAndVegetableName]: null,
    // [fruitName]: null,
    // [vegetablesName]: null,
    [wholeGrainName]: null,
    [refinedGrainName]: null,
    [legumesName]: null,
    [processedMeatName]: null,
    [poultryName]: null,
    [redMeatName]: null,
    [sugarySugar]: null,
    [waterIntakeName]: null,
  });
  const [exerciseSleepStress, setExerciseSleepStress] = useState({
    [exerciseSaunaFrequencyModelName]: null,
    [strengthTrainingName]: null,
    [stressQualityName]: null,
    [cardioName]: null,
    [sleepQualityName]: null,
    [actualSleepHoursName]: null,
  });
  const [supplements, setSupplements] = useState({
    [calciumName]: null,
    [fishOilOmega3Name]: null,
    [matchaGreenTeaName]: null,
  });
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [estiScore, setEstiScore] = useState(0);
  const [calculatorTimes, setCalculatorTimes] = useState(0);
  const [preferredUnitSystem, setPreferredUnitSystem] = useState("");
  const [greenTeaUnit, setGreenTeaUnit] = useState(""); // Add state for GreenTea unit
  const [waterUnit, setWaterUnit] = useState("");
  const [limit, setLimit] = useState({
    [artificiallySugar]: 36,
    [transFatIntakeName]: 50,
    [fruitName]: 10,
    [vegetablesName]: 10,
    [wholeGrainName]: 15 * 7, // per week
    [refinedGrainName]: 15 * 7, // per week
    [legumesName]: 4 * 7, // per week
    [processedMeatName]: 6 * 7, // per week
    [poultryName]: 6 * 7, // per week
    [redMeatName]: 6 * 7, // per week
    [sugarySugar]: 36,
    [waterIntakeName]: 8000,
    [calciumName]: 2000,
    [fishOilOmega3Name]: 5000,
    [matchaGreenTeaName]: Infinity,
    [actualSleepHoursName]: 14,
  });
  const [showWarning, setShowWarning] = useState(false);
  const onClose = () => {
    setShowWarning(false);
  };
  const handleUnitChange = (name, unit) => {
    if (name === "matchaOrGreenTea") {
      setGreenTeaUnit(unit);
      if (unit === "ml/day") {
        const newLimit = 2300;
        setLimit({ ...limit, [name]: newLimit });
        // const newVal = (supplements[name] / 0.0295735).toFixed(2);
        // setSupplements({ ...supplements, [name]: newVal });
      } else {
        const newLimit = Math.round(limit[name] / 29.5735);
        setLimit({ ...limit, [name]: newLimit });
        // const newVal = Math.round(supplements[name] * 0.0295735).toFixed(2);
        // setSupplements({ ...supplements, [name]: newVal });
      }
    }
    if (name === "water") {
      setWaterUnit(unit);
      if (unit === "ml/day") {
        const newLimit = 8000;
        setLimit({ ...limit, [name]: newLimit });
        // const newVal = Math.round(diet[name] / 0.0295735);
        // setDiet({ ...diet, [name]: newVal });
      } else {
        const newLimit = Math.round(limit[name] / 29.5735);
        setLimit({ ...limit, [name]: newLimit });
        // const newVal = Math.round(diet[name] * 0.0295735);
        // setDiet({ ...diet, [name]: newVal });
      }
    }
  };

  const handleUnitDefaultChange = (name, unit) => {
    if (name === "matchaOrGreenTea") {
      setGreenTeaUnit(unit);
    }
    if (name === "water") {
      setWaterUnit(unit);
    }
    //console.log(`Default unit for ${name} set to ${unit}`);
  };
  const [errors, setErrors] = useState({});
  const errorMessage = "Error: Please fill out this field or enter 0!";

  const retrieveData = (state, setState, data) => {
    let values = {...state};
    Object.keys(state).forEach((key) => {
      if (data.hasOwnProperty(key)) {
        values[key] = data[key];
      }
    })
    setState(values);
  }

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("mysUser")).userId;
    const fetchUser = async (userId) => {
      try {
        const data = await fetchUserInfo(userId);
        // console.log("what is the lifespan data", data);
        // console.log("what is the lifespan socre: ", data.lifespan_score);
        
        setScore(data.lifespan_score);
        setEstiScore(data.estimated_lifespan_score);
        // setEstiScore(data.optimal_lifespan)
        console.log("what is the calculator times: ", data.number_submitted);
        setCalculatorTimes(data.number_submitted);
        console.log('data: ', data)
        retrieveData(aboutMe, setAboutMe, data);
        retrieveData(diet, setDiet, data);
        retrieveData(exerciseSleepStress, setExerciseSleepStress, data);
        retrieveData(supplements, setSupplements, data);
        console.log('diet: ', diet)
        if (data.number_submitted === CALCULATOR_TIMES) {
          setSubmitted(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      try {
        const data = await getScoreFromLocal(userId);
        // console.log(data)
        // console.log("what is the optimal lifespan socre: ", data.optimal_lifespan);
        setEstiScore(data.optimal_lifespan);
        // localStorage.setItem("estiScore", data.optimal_lifespan);
        

        // setCalculatorTimes(data.already_submitted)
        // if (data.already_submitted === 3) {
        //   setSubmitted(true);
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUser(userId);
  }, []);

  // useEffect(() => {
  //   if (estiScore !== undefined || score !== undefined) {
  //     localStorage.setItem("estiScore", estiScore);
  //     localStorage.setItem("score", score);
  //   }
  // }, [estiScore, score]);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("mysUser")).userId;
    const fetchUser = async (userId) => {
      try {
        const userData = await fetchUserData(userId);
        setPreferredUnitSystem(userData.preferred_unit_system);
        if (userData.preferred_unit_system === "imperial") {
          setGreenTeaUnit("oz/day");
          setWaterUnit("oz/day");
          const newWaterLimit = Math.round(limit[waterIntakeName] / 29.5735);
          const newGreenTeaLimit = Math.round(
            limit[matchaGreenTeaName] / 29.5735
          );
          // console.log("water limit: " + newWaterLimit);
          setLimit({
            ...limit,
            [waterIntakeName]: newWaterLimit,
            [matchaGreenTeaName]: newGreenTeaLimit,
          });
          // console.log("green tea limit: " + newGreenTeaLimit);
        } else {
          setGreenTeaUnit("ml/day");
          setWaterUnit("ml/day");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUser(userId);
  }, []);

  const submitFormData = async (FormData, formDbData) => {
    try {
      if (calculatorTimes === 0) {
        const res = await fetchProbData(FormData);
      } else {
        const res = await updateProbData(FormData);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    await fetchDBData(formDbData);
  };

  const updateFormData = async (FormData) => {
    try {
      const res = await updateProbData(FormData);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const errorHandling = () => {
    const newErrors = {};
    let allFilledOut = true;
    const fieldToSkip = [];

    if (aboutMe[CIGARETTES_USE] == "0") {
      // console.log('never used');
      fieldToSkip.push(
        "smoking_duration",
        smoking_frequencyName
      );
    } else if (aboutMe[CIGARETTES_USE] == "1") {
      // console.log('former user');
      fieldToSkip.push(smoking_frequencyName);
    } else if (aboutMe[CIGARETTES_USE] == "2") {
      // console.log('current user');
    } else {
      fieldToSkip.push(
        "smoking_duration",
        smoking_frequencyName
      );
    }

    Object.entries(aboutMe).forEach(([key, value]) => {
      if (
        (value !== 0 && !value && !fieldToSkip.includes(key)) ||
        (key === "dob" && new Date(aboutMe[DATE_OF_BIRTH]) > new Date())
      ) {
        if (key === "dob") {
          newErrors[key] =
            "Error: Date of Birth must be less than current date!";
        } else {
          newErrors[key] = errorMessage;
        }
        // console.log(key + " is not filled out");
        allFilledOut = false;
      } else {
        newErrors[key] = "";
      }
    });

    Object.entries(diet).forEach(([key, value]) => {
      if (value !== 0 && !value) {
        newErrors[key] = errorMessage;
        // console.log(key + ' is not filled out')
        allFilledOut = false;
      } else {
        newErrors[key] = "";
      }
    });

    Object.entries(exerciseSleepStress).forEach(([key, value]) => {
      if (value !== 0 && !value) {
        newErrors[key] = errorMessage;
        // console.log(key + ' is not filled out');
        allFilledOut = false;
      } else {
        newErrors[key] = "";
      }
    });

    Object.entries(supplements).forEach(([key, value]) => {
      if (value !== 0 && !value) {
        newErrors[key] = errorMessage;
        // console.log(key + ' is not filled out')
        allFilledOut = false;
      } else {
        newErrors[key] = "";
      }
    });

    setErrors(newErrors);
    // console.log(errors);
    return allFilledOut;
  };

  // Get today's date and calculate yesterday's date in the format 'YYYY-MM-DD'
  const today = new Date();
  today.setDate(today.getDate() - 1); // Exclude today
  const maxDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD

  // let allFilledOut = null;
  const handleSubmit = (e) => {
    e.preventDefault();
    const allFilledOut = errorHandling();

    if (!allFilledOut) {
      // console.log('Some fields are not filled out!');
      window.scrollTo({ top: 0, behavior: "smooth" });
      // alert('Please fill out all fields!');
      return;
    } else {
      // console.log('All fields are filled out!')
    }

    setScore();
    setEstiScore();

    const userInfo = JSON.parse(localStorage.getItem("mysUser"));
    const formDbData = {
      ...userInfo,
      ...aboutMe,
      ...diet,
      ...exerciseSleepStress,
      ...supplements,
      waterUnit: waterUnit,
      greenTeaUnit: greenTeaUnit,
      // smoking_frequency: Number(aboutMe["smoking_frequency"]),
      // smoking_status: !aboutMe[CURRENT_CIGARETTES_YEARS]
      //   ? Number(aboutMe[FORMER_CIGARETTES_YEARS])
      //   : Number(aboutMe[CURRENT_CIGARETTES_YEARS]),
    };
    console.log(formDbData);
    let formPredictData = {
      ...userInfo,
      ...aboutMeModel,
      ...dietModel,
      ...exerciseSleepStressModel,
      ...supplementsModel,
      waterUnit: waterUnit,
      greenTeaUnit: greenTeaUnit,
      number_submitted: calculatorTimes + 1,
      lifespan_score: score,
      // estimated_lifespan: estiScore,
      // smoking_status: aboutMe[CIGARETTES_USE],
      // smoking_frequency: Number(aboutMe["smoking_frequency"]),
    };
    formPredictData["user_id"] = userInfo["userId"];
    const genderVal = formPredictData[GENDER] == '0' ? "Male" : "Female";
    formPredictData[GENDER] = genderVal;
    console.log(formPredictData);
    submitFormData(formPredictData, formDbData)
      .then(() => {
        getScoreFromLocal(userInfo.userId)
          .then((response) => {
            // console.log(response);
            const newScore = response.all_cause_mortality_predicted_lifespan;
            // console.log('new score: ' + newScore);
            // console.log('new opt score: ' + response.optimal_lifespan);
            setScore(newScore);
            setEstiScore(response.optimal_lifespan);
            console.log(waterUnit);
            console.log(greenTeaUnit);
            const formPredictData = {
              ...userInfo,
              ...aboutMeModel,
              ...dietModel,
              ...exerciseSleepStressModel,
              ...supplementsModel,
              lifespan_score: newScore,
              estimated_lifespan_score: response.optimal_lifespan,
              user_id: userInfo["userId"],
              // smoking_status: Number(aboutMe["smoking_duration"]),
              // smoking_frequency: Number(aboutMe["smoking_frequency"]),
              waterUnit: waterUnit,
              greenTeaUnit: greenTeaUnit,
            };
            console.log(formPredictData);
            updateFormData(formPredictData);
          })
          .catch((error) => {
            console.error("Error fetching data", error);
          });
      })
      .catch((error) => {
        console.error("Error submitting form data", error);
        setShowWarning(true);
        return;
      });

    setCalculatorTimes(calculatorTimes + 1);
    setSubmitted(true);
    // try {
    //     axios.post("/modelapi/optimization_model/",
    //     {"user_id": JSON.parse(localStorage.getItem('mysUser'))['userId']},
    //     {
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    //     }
    // )
    //     .then(response => {
    //         console.log(response.data)
    //         const scores = Number(response.data["all_cause_mortality_predicted_lifespan"]).toFixed(2)
    //         setEstiScore(scores)
    //     })
    // }
    // catch (error) {
    //     console.log(error)
    // }
  };

  useEffect(() => {
    const newValue = {
      ...aboutMeModel,
      [DATE_OF_BIRTH]: aboutMe[DATE_OF_BIRTH],
      [aboutMeAgeModelName]: aboutMe[AGE],
      [aboutMeAlcoholModelName]: aboutMe[ALCOHOL_DRINKS_PER_WEEK],
      [aboutMeGenderModelName]: aboutMe[GENDER],
      [aboutMeSmokingDurationModelName]: aboutMe['smoking_duration'],
      [aboutMeSmokingFrequencyModelName]: aboutMe[smoking_frequencyName],
      [aboutMeSmokingStatusModelName]: aboutMe[CIGARETTES_USE],
    };
    setAboutMeModel(newValue);
  }, [aboutMe]);
  useEffect(() => {
    setExerciseSleepStressModel({
      ...exerciseSleepStressModel,
      [exerciseSaunaFrequencyModelName]:
        exerciseSleepStress[exerciseSaunaFrequencyModelName],
      [exerciseStrengthTrainingModelName]:
        exerciseSleepStress[strengthTrainingName],
      [nodeStressQualityModelName]: exerciseSleepStress[stressQualityName],
      [exerciseCardioModelName]: exerciseSleepStress[cardioName],
      [mySleepSleepDurationModelName]:
        exerciseSleepStress[actualSleepHoursName],
      [mySleepSleepQualityModelName]: exerciseSleepStress[sleepQualityName],
    });
  }, [exerciseSleepStress]);
  useEffect(() => {
    setSupplementsModel({
      ...supplementsModel,
      [supplementsCalciumModelName]: supplements[calciumName],
      [supplementsFishOilOmega3ModelName]: supplements[fishOilOmega3Name],
      [supplementsGreenTeaModelName]: supplements[matchaGreenTeaName],
    });
  }, [supplements]);
  useEffect(() => {
    setDietModel({
      ...dietModel,
      [dietArtificialSweetenerModelName]: diet[artificiallySugar],
      [dietCalorieRestrictionModelName]: diet[calorieRestrictionName],
      [dietFatTransModelName]: diet[transFatIntakeName],
      [dietFruitsAndVeggiesModelName]: diet[fruitAndVegetableName],
      [dietGrainRefinedModelName]: diet[refinedGrainName],
      [dietGrainUnrefinedModelName]: diet[wholeGrainName],
      [dietLegumesModelName]: diet[legumesName],
      [dietMeatProcessedModelName]: diet[processedMeatName],
      [dietMeatUnprocessedModelName]: diet[redMeatName],
      [dietMeatPoultryModelName]: diet[poultryName],
      [dietRefinedSugarModelName]: diet[sugarySugar],
      [dietWaterModelName]: diet[waterIntakeName],
    });
  }, [diet]);

  const [userAgeFromDB, setUserAgeFromDB] = useState(null);
  const userId = JSON.parse(localStorage.getItem("mysUser")).userId;

  useEffect(() => {
    const fetchUserInfo = async (userId) => {
      try {
        const res = await getLifestyleResponseData(userId);
        setUserAgeFromDB(res.data["age"]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUserInfo(userId);
  }, []);



  const calculateAge = (dob) => {
    if (!dob) return null;
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const userAge = calculateAge(aboutMe[DATE_OF_BIRTH]);
  const [showButton, setShowButton] = useState(true);
  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <div className="questionnaire_title">
        Basic Info for Lifespan Calculator
      </div>
      <form onSubmit={handleSubmit}>
        {calculatorTimes !== CALCULATOR_TIMES && (
          <div className="cal_container">
            <AboutMe
              dataState={aboutMe}
              setDataState={setAboutMe}
              errors={errors}
              setErrors={setErrors}
              maxDate={maxDate} // Pass maxDate to disable future dates and today
            />
            <Diet
              dataState={diet}
              setDataState={setDiet}
              waterUnit={waterUnit}
              onChangeUnit={handleUnitChange}
              onChangeUnitDefault={handleUnitDefaultChange}
              errors={errors}
              limit={limit}
            />
            <ExerciseSleepStress
              dataState={exerciseSleepStress}
              setDataState={setExerciseSleepStress}
              errors={errors}
              limit={limit}
            />
            <Supplements
              dataState={supplements}
              setDataState={setSupplements}
              errors={errors}
              onChangeUnit={handleUnitChange}
              onChangeUnitDefault={handleUnitDefaultChange}
              greenTeaUnit={greenTeaUnit} // Pass the unit state as prop
              limit={limit}
            />
          </div>
        )}
        {/* {calculatorTimes === CALCULATOR_TIMES &&  !user.isPremium &&(
          <div className="cal_container">
            You have tried the Lifespan Calculator. Subscribe and try more
            features!
          </div>
        )} */}

        {((score && estiScore) || submitted) && (
          <CalculatorResponse score={score} estiScore={estiScore} calculatorTimes={calculatorTimes} CALCULATOR_TIMES={CALCULATOR_TIMES}  />
        )}
        {/* {!submitted &&  */}
        {/* Display Submit button only when !(score && estiScore) || submitted */}
        {(!(score && estiScore)) && (
          <div className="inlineContainer" style={{ marginTop: "30px" }}>
            <button
              type="submit"
              variant="dark"
              style={{ minWidth: "225px", height: "45px" }}
              disabled={calculatorTimes >= CALCULATOR_TIMES}
              // onClick={handleSubmit}
            >
              {calculatorTimes === 0
                ? "Submit"
                : calculatorTimes >= CALCULATOR_TIMES
                ? "You have used up all 3 free attempts to calculate your lifespan"
                : `You can calculate ${CALCULATOR_TIMES - calculatorTimes} times`}
            </button>
          </div>
        )}
      </form>
      {((userAge !== null && userAge < 18) || (userAgeFromDB !== null && userAgeFromDB < 18)) && (
        <p style={{ textAlign: "center" }}>
          Note: Our models are not calibrated for people under 18, so we cannot
          estimate how much your lifespan might increase by modifying your
          lifestyle.
        </p>
      )}
    </div>
  );
}

export default Calculator;
