import React, { useState, useEffect, useMemo } from 'react';
import { Nav, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

// Import all test components
import PittTest from './progress-tests/PittSleepQuality';
import StressTest from './progress-tests/StressScale';
import DigitTest from './progress-tests/DigitSpanTest';
import ReactionTimeTest from './progress-tests/ReactionTimeTest';
import SelfReactionTest from './progress-tests/SelfReactionTest';
import HappinessTest from './progress-tests/HappinessTest';
import QoLScale from './progress-tests/QoLScale';
import AppearanceTest from './progress-tests/AppearanceTest';
import SexTest from './progress-tests/SexTest';
import BrainFogTest from './progress-tests/BrainFogTest';
import SF36Test from './progress-tests/SF36Test';

function TestsProgress() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [testData, setTestData] = useState([]);
    const [activeButton, setActiveButton] = useState("all");
    const [selectedTest, setSelectedTest] = useState(null);
    const [showButton, setShowButton] = useState(true);

    const userId = useMemo(() => {
        let user = localStorage.getItem("mysUser");
        user = user ? JSON.parse(user) : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
        return user.userId;
    }, []);

    const baseUrlAWS = process.env.REACT_APP_baseUrl;
    const baseUrl = `${baseUrlAWS}api/myprogress`;

    const fetchTestData = async (value) => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `${baseUrl}/read-all-test-scores-by-user/${userId}/${value}`,
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            );
            setTestData(response.data.all_scores_responses);
        } catch (error) {
            if (error.response && error.response.status === 500) {
                return null;
            } else {
                console.error('Error fetching data: ', error);
                throw error;
            }
        } finally {
            setIsLoading(false);
            
        }
    };

    useEffect(() => {
        fetchTestData("all");
    }, []);

    useEffect(() => {
        let lastScrollY = window.scrollY;
        const navbarHeight = 64; // Adjust this to match your navbar height
        
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            setShowButton(currentScrollY <= navbarHeight);
            lastScrollY = currentScrollY;
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getLast7DaysData = (testId) => {
        // Get dates for the last 7 days
        const dates = Array.from({length: 7}, (_, i) => {
            const d = new Date();
            d.setDate(d.getDate() - i);
            return d.toISOString().split('T')[0];
        }).reverse();

        // Filter and map test data
        const testScores = testData
            .filter(data => data.testId === testId)
            .reduce((acc, curr) => {
                const date = curr.submission_date.split('T')[0];
                if (dates.includes(date)) {
                    acc[date] = curr.score;
                }
                return acc;
            }, {});

        // Calculate average and range for scaling
        const scores = Object.values(testScores);
        const average = scores.length > 0 
            ? scores.reduce((a, b) => a + b, 0) / scores.length 
            : 0;

        // Set default range if not enough data points
        let range;
        if (scores.length === 0) {
            range = 10; // Default range for no data
        } else if (scores.length === 1) {
            range = Math.abs(scores[0]) * 0.5; // 50% of the single value
        } else {
            const maxScore = Math.max(...scores);
            const minScore = Math.min(...scores);
            range = Math.max(maxScore - average, average - minScore) * 1.5;
        }

        // Ensure minimum range
        range = Math.max(range, 1);

        return {
            labels: dates.map(date => {
                const d = new Date(date);
                return d.toLocaleDateString('en-US', { weekday: 'short' });
            }),
            datasets: [
                {
                    label: 'Score',
                    data: dates.map(date => testScores[date] || null),
                    borderColor: '#495057',
                    backgroundColor: 'rgba(73, 80, 87, 0.1)',
                    borderWidth: 2,
                    tension: 0.4,
                    pointRadius: 3,
                    pointBackgroundColor: '#495057',
                    spanGaps: true
                },
                {
                    label: 'Average',
                    data: Array(7).fill(average),
                    borderColor: '#6c757d',
                    borderWidth: 1,
                    borderDash: [5, 5],
                    pointRadius: 0,
                    fill: false,
                    spanGaps: true
                }
            ],
            average,
            range
        };
    };

    const getStyles = () => ({
        container: {
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '24px',
            margin: '2rem',
            marginTop: '3rem',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            position: 'relative'
        },
        testGrid: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '1.5rem',
            marginTop: '2rem'
        },
        testCard: {
            border: 'none',
            borderRadius: '12px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
            transition: 'all 0.2s ease',
            cursor: 'pointer',
            backgroundColor: '#fff',
            '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 4px 12px rgba(0,0,0,0.12)'
            }
        },
        periodButtons: {
            marginBottom: '2rem',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end'
        },
        returnButton: {
            position: 'absolute',
            top: '20px',
            right: '20px',
            padding: '8px 16px',
            backgroundColor: '#6c757d',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '0.9rem',
            zIndex: 1000
        },
        title: {  
            marginBottom: '1rem'
        },
        statsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1.5rem',
            padding: '1rem',
            backgroundColor: '#f8f9fa',
            borderRadius: '8px'
        },
        statItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '4px'
        },
        statLabel: {
            color: '#6c757d',
            fontSize: '0.85rem',
            fontWeight: '500'
        },
        statValue: {
            color: '#212529',
            fontSize: '1.1rem',
            fontWeight: '600'
        },
        chartOptions: (average, range) => ({
            maintainAspectRatio: false,
            plugins: {
                legend: { 
                    display: true,
                    position: 'top',
                    align: 'end',
                    labels: {
                        boxWidth: 15,
                        usePointStyle: true,
                        pointStyle: 'line',
                        color: '#6c757d',
                        font: {
                            size: 11
                        }
                    }
                }
            },
            scales: {
                y: {
                    min: Math.max(0, average - range),
                    max: average + range,
                    grid: {
                        color: '#f0f0f0'
                    },
                    ticks: {
                        color: '#6c757d',
                        font: {
                            size: 10
                        }
                    }
                },
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        color: '#6c757d',
                        font: {
                            size: 10
                        }
                    }
                }
            }
        })
    });

    const tests = [
        { id: 'pitt', name: 'Pittsburgh Sleep Quality Index', component: PittTest },
        { id: 'stress', name: 'Perceived Stress Scale', component: StressTest },
        { id: 'digit', name: 'Digit Memory Test', component: DigitTest },
        { id: 'reactiontime', name: 'Reaction Time Test', component: ReactionTimeTest },
        { id: 'selfreaction', name: 'Self Reaction Test', component: SelfReactionTest },
        { id: 'happiness', name: 'Happiness Scale', component: HappinessTest },
        { id: 'qol', name: 'Quality of Life Scale', component: QoLScale },
        { id: 'appearance', name: 'Appearance Scale', component: AppearanceTest },
        { id: 'sex', name: 'Sex Questionnaire', component: SexTest },
        { id: 'brainfog', name: 'Brain Fog Scale', component: BrainFogTest },
        { id: '36item', name: 'General Health Inventory', component: SF36Test }
    ];

    const calculateTestStats = (testId) => {
        const testScores = testData.filter(data => data.testId === testId);
        if (testScores.length === 0) return { daysSince: 'Never', avgScore: 'N/A' };

        const lastDate = new Date(testScores[0].submission_date);
        const today = new Date();
        const diffTime = Math.abs(today - lastDate);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const daysSince = diffDays === 0 ? 'Today' : 
                         diffDays === 1 ? 'Yesterday' :
                         `${diffDays} days ago`;

        const avgScore = (testScores.reduce((acc, curr) => acc + curr.score, 0) / testScores.length).toFixed(1);

        return { daysSince, avgScore };
    };

    const renderTestComponent = () => {
        if (!selectedTest) return null;
        const TestComponent = tests.find(t => t.id === selectedTest)?.component;
        return TestComponent ? <TestComponent /> : null;
    };

    const containerStyle = {
        ...getStyles().container,
        paddingTop: '64px' // Match navbar height
    };

    return (
        <div style={containerStyle}>
            {showButton && (
                <button 
                    onClick={() => navigate('/progress')}
                    style={getStyles().returnButton}
                >
                    Return to My Progress
                </button>
            )}
            <h2 style={getStyles().title}>Health Tests</h2>
            
            {isLoading ? (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    <Nav variant="pills" className="mb-4">
                        {/* ... existing nav items ... */}
                    </Nav>

                    {selectedTest ? (
                        <>
                            <Button 
                                variant="outline-primary" 
                                className="mb-4"
                                onClick={() => setSelectedTest(null)}
                            >
                                ← Back to Tests
                            </Button>
                            {renderTestComponent()}
                        </>
                    ) : (
                        <>

                            <div style={getStyles().testGrid}>
                                {tests.map((test) => {
                                    const { daysSince, avgScore } = calculateTestStats(test.id);
                                    const chartData = getLast7DaysData(test.id);
                                    return (
                                        <Card 
                                            key={test.id}
                                            style={getStyles().testCard}
                                            onClick={() => setSelectedTest(test.id)}
                                        >
                                            <Card.Body>
                                                <Card.Title>{test.name}</Card.Title>
                                                <div style={{height: '150px'}}>
                                                    <Line
                                                        options={getStyles().chartOptions(chartData.average, chartData.range)}
                                                        data={chartData}
                                                    />
                                                </div>
                                                <div style={getStyles().statsContainer}>
                                                    <div style={getStyles().statItem}>
                                                        <span style={getStyles().statLabel}>Last Completed</span>
                                                        <span style={getStyles().statValue}>{daysSince}</span>
                                                    </div>
                                                    <div style={getStyles().statItem}>
                                                        <span style={getStyles().statLabel}>Average Score</span>
                                                        <span style={getStyles().statValue}>{avgScore}</span>
                                                    </div>
                                                </div>
                                                <Button 
                                                    variant="primary" 
                                                    className="mt-3"
                                                    size="sm"
                                                >
                                                    Take Test
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default TestsProgress; 