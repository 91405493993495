import { createContext, useReducer, useEffect, useMemo } from 'react'
import jwt from 'jwt-decode';

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return { user: action.payload }
        case "LOGOUT":
            localStorage.removeItem("mysUser")
            return { user: null }
        case "UPDATE_USER":
                return { user: action.payload }
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(authReducer, {
        user: JSON.parse(localStorage.getItem("mysUser")) || null
    })

    const isTokenValid = (token) => {
        if (!token) return false;
    
        try {
          const decodedToken = jwt(token);
          const currentTime = Math.floor(Date.now() / 1000);
          return decodedToken.exp > currentTime; 
        } catch (error) {
          console.error("Invalid token:", error);
          return false;
        }
      };
      useEffect(() => {
        const checkTokenValidity = () => {
          const accessToken = localStorage.getItem("accessToken");
    
          if (!isTokenValid(accessToken)) {
            dispatch({ type: "LOGOUT" });
            console.log("logged out")
          }
        };
    
        checkTokenValidity();
      }, [state.user]);    

    useEffect(() => {
        if (state.user) {
            localStorage.setItem("mysUser", JSON.stringify(state.user))
        } else {
            localStorage.removeItem("mysUser")
        }
    }, [state.user])

    console.log("AuthContext state: ", state)
    const contextValue = useMemo(() => ({ ...state, dispatch }), [state])
    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}