import React, { useState, useEffect, useMemo } from 'react';
import {
  Accordion,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { setCookieValue, getCookie } from '../../utils/cookie';
import { submitData, fetchUserData, updateData } from './action';
import SupplementWarning from '../../components/SupplementWarning';
import './LogForm.css';
import {
  processedMeat,
  redMeat,
  poultry,
  fish,
  fv,
  dairy,
  wholeGrain,
  refinedGrain,
  legumes,
  saturatedFat,
  transFat,
  water,
  sugar,
  sweetener,
  alcohol,
  cigarettes,
  calorieRestriction,
  fasting,
  sickDays,
  isSick
} from './NameTable/DietNameTable';
import {
  lCardio,
  mCardio,
  hCardio,
  strength,
  balance,
  boneDensity,
  stretching,
  saunaFreq,
  saunaDuration,
} from './NameTable/ExerciseNameTable';
import {
  multivitamin,
  vitaminA,
  vitaminB6,
  vitaminB12,
  folicAcid,
  vitaminC,
  vitaminD,
  vitaminE,
  vitaminK,
  calcium,
  collagenPeptides,
  fiber,
  omega3,
  greenTea,
  iron,
  magnesium,
  oliveOil,
  probiotics,
  protein,
  zinc,
  sleep,
} from './NameTable/SupplementsNameTable';
import PopUpMessage from '../../components/PopUpMessage';

function LogForm(props) {
  const userId = useMemo(() => {
    let user = localStorage.getItem('mysUser');
    user = user
      ? JSON.parse(user)
      : { userId: '64ad93a2-a276-43e3-8304-be5fbd587ca1' };
    return user.userId;
  });

  const [validRange, setValidRange] = useState(true);
  const [isFormValid, setIsFormValid] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  //units
  const [waterUnit, setWaterUnit] = useState('oz');
  const [greenTeaUnit, setGreenTeaUnit] = useState('oz');
  const [oliveOilUnit, setOliveOilUnit] = useState('g');
  const [waterLimit, setWaterLimit] = useState(7.5);
  const [teaLimit, setTeaLimit] = useState(3785);
  const [oilLimit, setOilLimit] = useState(224);
  // const [activeKey, setActiveKey] = useState(
  //   props.submitted ? ['0', '1', '2', '3'] : ['']
  // );
  // const [activeKey, setActiveKey] = useState(['']);
  const [toxicLevel, setToxicLevel] = useState({
    vitamin_a: 3000,
    vitamin_b6: 100,
    vitamin_b12: Infinity,
    folic_acid: Infinity,
    vitamin_c: 2000,
    vitamin_d: 100,
    vitamin_e: Infinity,
    vitamin_k: Infinity,
    calcium: 3000,
    dietary_fiber: Infinity,
    fish_oil_omega_3: 5000,
    green_tea: Infinity,
    iron: 45,
    magnesium: 350,
    olive_oil: Infinity,
    protein_supplements: Infinity,
    zinc: 40,
    water: 8000,
  });

  const tooltip = (
    <Tooltip id='button-tooltip-2'>
      Input value is too high, please validate your entry.
    </Tooltip>
  );

  // const tooltip = <Tooltip id="button-tooltip-2">Warning: invalid or alarming input</Tooltip>

  const toxic_tooltip = (limit, unit) => (
    <Tooltip id='button-tooltip-2'>
      Values by {limit} {unit} are considered toxic. Please correct your
      entries.
    </Tooltip>
  );

  useEffect(() => {
    if (!props.submitted) {
      props.setActiveKey([]);
    }
    // setActiveKey(props.submitted ? ['0', '1', '2', '3'] : ['']);
  }, [props.submitted]);

  useEffect(() => {
    const validRange = () => {
      // check date range
      if (!props.selectRange) {
        for (let [start, end] of props.loggedDates) {
          // console.log(start, end);
          // console.log(props.date1, props.date2);
          const startDate = new Date(start);
          const endDate = new Date(end);

          if (startDate > props.rawdate1 && endDate < props.rawdate2) {
            console.log('selected range includes logged date');
            return false;
          }
          if (startDate < props.rawdate1 && endDate > props.rawdate1) {
            console.log('first selected date within a logged date range');
            return false;
          }
          if (startDate < props.rawdate2 && endDate > props.rawdate2) {
            return false;
          }
        }
        return true;
      }
      return true;
    };

    const fetchUData = async (userId) => {
      try {
        const data = await fetchUserData(userId);

        // alert(data.preferred_unit_system)
        if (data.preferred_unit_system === 'metric') {
          setWaterUnit('ml');
          setGreenTeaUnit('mL');
          setOliveOilUnit('g');
          setToxicLevel({ ...toxicLevel, water: 8000 });
          setWaterLimit(8000);
          setTeaLimit(3785);
          setOilLimit(224);
        } else {
          setWaterUnit('oz');
          setGreenTeaUnit('oz');
          // setOliveOilUnit('Tbsp');
          setToxicLevel({ ...toxicLevel, water: 271 });
          setWaterLimit(271);
          setTeaLimit(128);
          // setOilLimit(16);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    setValidRange(validRange());
    fetchUData(userId);
  }, [props.rawdate1, props.rawdate2]);

  const validateInputs = () => {
    // Validate inputs based on their conditions, similar to the conditions in OverlayTrigger's 'show' prop
    const validateRegex = /^(null|[0-9]*\.?[0-9]+|)$/;

    const isValid =
      validateRegex.test(props.logData[processedMeat]) &&
      props.logData[processedMeat] <= 35 * props.numDays &&
      validateRegex.test(props.logData[redMeat]) &&
      props.logData[redMeat] <= 35 * props.numDays &&
      validateRegex.test(props.logData[poultry]) &&
      props.logData[poultry] <= 35 * props.numDays &&
      validateRegex.test(props.logData[fish]) &&
      props.logData[fish] <= 35 * props.numDays &&
      validateRegex.test(props.logData[fv]) &&
      props.logData[fv] <= 10 &&
      validateRegex.test(props.logData[dairy]) &&
      props.logData[dairy] <= 10 &&
      validateRegex.test(props.logData[wholeGrain]) &&
      props.logData[wholeGrain] <= 70 * props.numDays &&
      validateRegex.test(props.logData[refinedGrain]) &&
      props.logData[refinedGrain] <= 70 * props.numDays &&
      validateRegex.test(props.logData[legumes]) &&
      props.logData[legumes] <= 70 * props.numDays &&
      validateRegex.test(props.logData[saturatedFat]) &&
      props.logData[saturatedFat] <= 300 &&
      validateRegex.test(props.logData[transFat]) &&
      props.logData[transFat] <= 300 &&
      validateRegex.test(props.logData[water]) &&
      props.logData[water] <= waterLimit &&
      validateRegex.test(props.logData[sugar]) &&
      props.logData[sugar] <= 500 &&
      validateRegex.test(props.logData[sweetener]) &&
      props.logData[sweetener] <= 50 &&
      validateRegex.test(props.logData[alcohol]) &&
      props.logData[alcohol] <= 15 * props.numDays &&
      validateRegex.test(props.logData[cigarettes]) &&
      props.logData[cigarettes] <= 60 &&
      validateRegex.test(props.logData[lCardio]) &&
      props.logData[lCardio] <= 1680 * props.numDays &&
      validateRegex.test(props.logData[mCardio]) &&
      props.logData[mCardio] <= 1680 * props.numDays &&
      validateRegex.test(props.logData[hCardio]) &&
      props.logData[hCardio] <= 1680 * props.numDays &&
      validateRegex.test(props.logData[strength]) &&
      props.logData[strength] <= 1680 * props.numDays &&
      validateRegex.test(props.logData[balance]) &&
      props.logData[balance] <= 7 * props.numDays &&
      validateRegex.test(props.logData[boneDensity]) &&
      props.logData[boneDensity] <= 7 * props.numDays &&
      validateRegex.test(props.logData[stretching]) &&
      props.logData[stretching] <= 7 * props.numDays &&
      validateRegex.test(props.logData[saunaFreq]) &&
      props.logData[saunaFreq] <= 7 * props.numDays &&
      validateRegex.test(props.logData[saunaDuration]) &&
      props.logData[saunaDuration] <= 120 * props.numDays &&
      validateRegex.test(props.logData[vitaminA]) &&
      props.logData[vitaminA] <= 3000 &&
      validateRegex.test(props.logData[vitaminB6]) &&
      props.logData[vitaminB6] <= 100 &&
      validateRegex.test(props.logData[vitaminB12]) &&
      props.logData[vitaminB12] <= Infinity &&
      validateRegex.test(props.logData[folicAcid]) &&
      props.logData[folicAcid] <= Infinity &&
      validateRegex.test(props.logData[vitaminC]) &&
      props.logData[vitaminC] <= 2000 &&
      validateRegex.test(props.logData[vitaminD]) &&
      props.logData[vitaminD] <= 100 * props.numDays &&
      validateRegex.test(props.logData[vitaminE]) &&
      props.logData[vitaminE] <= Infinity &&
      validateRegex.test(props.logData[vitaminK]) &&
      props.logData[vitaminK] <= Infinity &&
      validateRegex.test(props.logData[calcium]) &&
      props.logData[calcium] <= 3000 &&
      validateRegex.test(props.logData[fiber]) &&
      props.logData[fiber] <= Infinity &&
      validateRegex.test(props.logData[omega3]) &&
      props.logData[omega3] <= 5000 &&
      validateRegex.test(props.logData[greenTea]) &&
      props.logData[greenTea] <= Infinity &&
      validateRegex.test(props.logData[iron]) &&
      props.logData[iron] <= 45 &&
      validateRegex.test(props.logData[magnesium]) &&
      props.logData[magnesium] <= 350 &&
      validateRegex.test(props.logData[oliveOil]) &&
      props.logData[oliveOil] <= Infinity &&
      validateRegex.test(props.logData[protein]) &&
      props.logData[protein] <= Infinity &&
      validateRegex.test(props.logData[zinc]) &&
      props.logData[zinc] <= 40 &&
      validateRegex.test(props.logData[sleep]) &&
      props.logData[sleep] <= 14;

    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateInputs();
  }, [props.logData]);

  const handleSubmit = (e) => {
    // console.log('on submit');
    e.preventDefault();

    if (!isFormValid) {
      console.log('invalid form');
      return;
    }

    let logRes = {};

    logRes['user'] = userId;
    logRes['start_date'] = props.dateProp;
    logRes['end_date'] = props.date2Prop;
    logRes['meat_processed'] = props.logData[processedMeat];
    logRes['meat_unprocessed'] = props.logData[redMeat];
    logRes['meat_poultry'] = props.logData[poultry];
    logRes['meat_fish'] = props.logData[fish];
    logRes['fruits_and_veggies'] = props.logData[fv];
    logRes['dairy'] = props.logData[dairy];
    logRes['grain_whole'] = props.logData[wholeGrain];
    logRes['grain_refined'] = props.logData[refinedGrain];
    logRes['legumes'] = props.logData[legumes];
    logRes['fat_saturated'] = props.logData[saturatedFat];
    logRes['fat_trans'] = props.logData[transFat];
    logRes['water'] = props.logData[water];
    logRes['refined_sugar'] = props.logData[sugar];
    logRes['artificial_sweetener'] = props.logData[sweetener];
    logRes['alcohol'] = props.logData[alcohol];
    logRes['cigarettes'] = props.logData[cigarettes];
    logRes['calorie_restriction'] = props.logData[calorieRestriction];
    logRes['fasting'] = props.logData[fasting];
    logRes['cardio_low'] = props.logData[lCardio];
    logRes['cardio_medium'] = props.logData[mCardio];
    logRes['cardio_high'] = props.logData[hCardio];
    logRes['strength_training'] = props.logData[strength];
    logRes['balance'] = props.logData[balance];
    logRes['bone_density'] = props.logData[boneDensity];
    logRes['stretching'] = props.logData[stretching];
    logRes['sauna_frequency'] = props.logData[saunaFreq];
    logRes['sauna_duration'] = props.logData[saunaDuration];
    logRes['multi_vitamins'] = props.logData[multivitamin];
    logRes['vitamin_a'] = props.logData[vitaminA];
    logRes['vitamin_b6'] = props.logData[vitaminB6];
    logRes['vitamin_b12'] = props.logData[vitaminB12];
    logRes['folic_acid'] = props.logData[folicAcid];
    logRes['vitamin_c'] = props.logData[vitaminC];
    logRes['vitamin_d'] = props.logData[vitaminD];
    logRes['vitamin_e'] = props.logData[vitaminE];
    logRes['vitamin_k'] = props.logData[vitaminK];
    logRes['calcium'] = props.logData[calcium];
    logRes['collagen_peptide'] = props.logData[collagenPeptides];
    logRes['dietary_fiber'] = props.logData[fiber];
    logRes['fish_oil_omega_3'] = props.logData[omega3];
    logRes['green_tea'] = props.logData[greenTea];
    logRes['iron'] = props.logData[iron];
    logRes['magnesium'] = props.logData[magnesium];
    logRes['olive_oil'] = props.logData[oliveOil];
    logRes['probiotics'] = props.logData[probiotics];
    logRes['protein_supplements'] = props.logData[protein];
    logRes['zinc'] = props.logData[zinc];
    logRes['sleep'] = props.logData[sleep];
    logRes['sickDays'] = props.logData[sickDays];
    logRes['isSick'] = props.logData[isSick];
    console.log('log data: ', props.logData);
    // console.log('log res: ', logRes);

    try {
      // Update existing data
      if (props.logData.hasOwnProperty('id')) {
        updateData(logRes, props.logData.id);
      } 
      // Submit new data
      else {
        submitData(logRes);
      }

      setShowPopup(true);
      // Remove popup message and change button text
      setTimeout(() => {
        setShowPopup(false);
        props.setSubmitted(true);
      }, 2000);
      // setActiveKey(['']);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };
  return (
    <div className='form-box'>
      <Accordion
        activeKey={props.activeKey}
        onSelect={(key) => props.setActiveKey(key)}
        alwaysOpen
      >
        {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}
        <Form onSubmit={handleSubmit}>
          <div className='inlineContainer'>
            <h2>Log Form</h2>
            {props.dateProp !== props.date2Prop && (
              <p>
                {props.dateProp} - {props.date2Prop}
              </p>
            )}
            {props.dateProp === props.date2Prop && <p>{props.dateProp}</p>}
            {!validRange && (
              <p style={{ color: 'red', fontSize: '20px' }}>(invalid range)</p>
            )}
          </div>
          <Accordion.Item eventKey='0'>
            <div className='inlineContainer'>
              <Accordion.Header>
                <Form.Text>Diet</Form.Text>
              </Accordion.Header>
            </div>
            <Accordion.Body>
              <div className='cal_select_group'>
                <Form.Label>Processed Meat</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Servings</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[processedMeat] > 35 * props.numDays}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='processedMeat'
                    name='processedMeat'
                    value={props.logData[processedMeat] ? props.logData[processedMeat] : ''}
                    className='cal_input_control'
                    // disabled={props.submitted}
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value < 0) {
                        value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [processedMeat]: value,
                      });
                      setCookieValue('processedMeat', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Red Meat</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Servings</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[redMeat] > 35 * props.numDays}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='redMeat'
                    name='redMeat'
                    value={props.logData[redMeat] ? props.logData[redMeat] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [redMeat]: e.target.value,
                      });
                      setCookieValue('redMeat', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Poultry</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Servings</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[poultry] > 35 * props.numDays}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='poultry'
                    name='poultry'
                    value={props.logData[poultry] ? props.logData[poultry] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [poultry]: e.target.value,
                      });
                      setCookieValue('poultry', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Fish</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Servings</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[fish] > 35 * props.numDays}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='fish'
                    name='fish'
                    value={props.logData[fish] ? props.logData[fish] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [fish]: e.target.value,
                      });
                      setCookieValue('fish', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Fruits and Vegetables</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Ave Servings/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[fv] > 10}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='fv'
                    name='fv'
                    value={props.logData[fv] ? props.logData[fv] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [fv]: e.target.value,
                      });
                      setCookieValue('fv', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Dairy</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Ave Servings/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[dairy] > 10}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='dairy'
                    name='dairy'
                    value={props.logData[dairy] ? props.logData[dairy] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [dairy]: e.target.value,
                      });
                      setCookieValue('dairy', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Whole Grain</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Servings</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[wholeGrain] > 70 * props.numDays}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='wholeGrain'
                    name='wholeGrain'
                    value={props.logData[wholeGrain] ? props.logData[wholeGrain] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [wholeGrain]: e.target.value,
                      });
                      setCookieValue('wholeGrain', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Refined Grain</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Servings</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[refinedGrain] > 70 * props.numDays}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='refinedGrain'
                    name='refinedGrain'
                    value={props.logData[refinedGrain] ? props.logData[refinedGrain] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [refinedGrain]: e.target.value,
                      });
                      setCookieValue('refinedGrain', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Legumes</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Servings</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[legumes] > 70 * props.numDays}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='legumes'
                    name='legumes'
                    value={props.logData[legumes] ? props.logData[legumes] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [legumes]: e.target.value,
                      });
                      setCookieValue('legumes', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Saturated Fat</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg g/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[saturatedFat] > 300}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='saturatedFat'
                    name='saturatedFat'
                    value={props.logData[saturatedFat] ? props.logData[saturatedFat] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [saturatedFat]: e.target.value,
                      });
                      setCookieValue('saturatedFat', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Trans Fat</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg g/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[transFat] > 300}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='transFat'
                    name='transFat'
                    value={props.logData[transFat] ? props.logData[transFat] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [transFat] : e.target.value,
                      });
                      setCookieValue('transFat', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Water in all forms</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg {waterUnit}/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[water], `${waterUnit}/day`)}
                  show={props.logData[water] > toxicLevel[water]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='water'
                    name='water'
                    value={props.logData[water] ? props.logData[water] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [water]: e.target.value,
                      });
                      setCookieValue('water', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Sugar</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg g/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[sugar] > 500}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='sugar'
                    name='sugar'
                    value={props.logData[sugar] ? props.logData[sugar] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [sugar]: e.target.value,
                      });
                      setCookieValue('sugar', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Artificial Sweetener</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg g/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[sweetener] > 50}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='sweetener'
                    name='sweetener'
                    value={props.logData[sweetener] ? props.logData[sweetener] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [sweetener]: e.target.value,
                      });
                      setCookieValue('sweetener', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Alcohol</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Drinks</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[alcohol] > 15 * props.numDays}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='alcohol'
                    name='alcohol'
                    value={props.logData[alcohol] ? props.logData[alcohol] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [alcohol]: e.target.value,
                      });
                      setCookieValue('alcohol', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Cigarettes</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg Cigarettes/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[cigarettes] > 60}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='cigarettes'
                    name='cigarettes'
                    value={props.logData[cigarettes] ? props.logData[cigarettes] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [cigarettes]: e.target.value,
                      });
                      setCookieValue('cigarettes', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='checkbox_group'>
                <Form.Check
                  id='calorieRestriction'
                  name='calorieRestriction'
                  checked={props.logData[calorieRestriction]}
                  onChange={() => {
                    props.setLogData({
                      ...props.logData,
                      [calorieRestriction]: !props.logData[calorieRestriction],
                    });
                    // console.log('calorieRestriction: ', props.logData[calorieRestriction]);
                  }}
                  className='checkbox_check'
                />
                <Form.Label>Calorie Restriction</Form.Label>
              </div>
              <div className='checkbox_group'>
                <Form.Check
                  id='fasting'
                  name='fasting'
                  checked={props.logData[fasting]}
                  onChange={() => {
                    props.setLogData({
                      ...props.logData,
                      [fasting]: !props.logData[fasting],
                    });
                  }}
                  className='checkbox_check'
                />
                <Form.Label>Fasting</Form.Label>
              </div>
              
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <div className='inlineContainer'>
              <Accordion.Header>
                <Form.Text>Exercise</Form.Text>
              </Accordion.Header>
            </div>
            <Accordion.Body>
              <div className='cal_select_group'>
                <Form.Label>Low Intensity Cardio</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Minutes</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[lCardio] > 1680}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='lCardio'
                    name='lCardio'
                    value={props.logData[lCardio] ? props.logData[lCardio] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [lCardio]: e.target.value,
                      });
                      setCookieValue('lCardio', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Medium Intensity Cardio</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Minutes</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[mCardio] > 1680}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='mCardio'
                    name='mCardio'
                    value={props.logData[mCardio] ? props.logData[mCardio] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [mCardio]: e.target.value,
                      });
                      setCookieValue('mCardio', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>High Intensity Cardio</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Minutes</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[hCardio] > 1680}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='hCardio'
                    name='hCardio'
                    value={props.logData[hCardio] ? props.logData[hCardio] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [hCardio]: e.target.value,
                      });
                      setCookieValue('hCardio', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Strength Training</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Minutes</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[strength] > 1680}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='strength'
                    name='strength'
                    value={props.logData[strength] ? props.logData[strength] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [strength]: e.target.value,
                      });
                      setCookieValue('strength', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Balance</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Times</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[balance] > 7}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='balance'
                    name='balance'
                    value={props.logData[balance] ? props.logData[balance] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [balance]: e.target.value,
                      });
                      setCookieValue('balance', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Bone Density</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Times</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[boneDensity] > 7}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='boneDensity'
                    name='boneDensity'
                    value={props.logData[boneDensity] ? props.logData[boneDensity] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [boneDensity]: e.target.value,
                      });
                      setCookieValue('boneDensity', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Stretching</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Times</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[stretching] > 7}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='stretching'
                    name='stretching'
                    value={props.logData[stretching] ? props.logData[stretching] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [stretching]: e.target.value,
                      });
                      setCookieValue('stretching', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Sauna Frequency</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Times</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[saunaFreq] > 7}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='saunaFreq'
                    name='saunaFreq'
                    value={props.logData[saunaFreq] ? props.logData[saunaFreq] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [saunaFreq]: e.target.value,
                      });
                      setCookieValue('saunaFreq', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Sauna Duration</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total Minutes</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[saunaDuration] > 120}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='saunaDuration'
                    name='saunaDuration'
                    value={props.logData[saunaDuration] ? props.logData[saunaDuration] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [saunaDuration]: e.target.value,
                      });
                      setCookieValue('saunaDuration', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='2'>
            <div className='inlineContainer'>
              <Accordion.Header>
                <Form.Text>Supplements</Form.Text>
              </Accordion.Header>
            </div>
            <Accordion.Body>
              <SupplementWarning />
              <div className='checkbox_group'>
                <Form.Check
                  id='multivitamin'
                  name='multivitamin'
                  checked={props.logData[multivitamin]}
                  onChange={() => {
                    props.setLogData({
                      ...props.logData,
                      [multivitamin]: !props.logData[multivitamin],
                    });
                  }}
                  className='checkbox_check'
                />
                <Form.Label>Multi-Vitamins</Form.Label>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Vitamin A</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg ug/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[vitaminA], 'ug/day')}
                  show={props.logData[vitaminA] > toxicLevel[vitaminA]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='vitaminA'
                    name='vitaminA'
                    value={props.logData[vitaminA] ? props.logData[vitaminA] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [vitaminA]: e.target.value,
                      });
                      setCookieValue('vitaminA', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Vitamin B6</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg mg/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[vitaminB6], 'mg/day')}
                  show={props.logData[vitaminB6] > toxicLevel[vitaminB6]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='vitaminB6'
                    name='vitaminB6'
                    value={props.logData[vitaminB6] ? props.logData[vitaminB6] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [vitaminB6]: e.target.value,
                      });
                      setCookieValue('vitaminB6', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Vitamin B12</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg ug/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[vitaminB12], 'ug/day')}
                  show={props.logData[vitaminB12] > toxicLevel[vitaminB12]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='vitaminB12'
                    name='vitaminB12'
                    value={props.logData[vitaminB12] ? props.logData[vitaminB12] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [vitaminB12]: e.target.value,
                      });
                      setCookieValue('vitaminB12', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Folic Acid</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg ug/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[folicAcid], 'ug/day')}
                  show={props.logData[folicAcid] > toxicLevel[folicAcid]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='folicAcid'
                    name='folicAcid'
                    value={props.logData[folicAcid] ? props.logData[folicAcid] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [folicAcid]: e.target.value,
                      });
                      setCookieValue('folicAcid', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Vitamin C</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg mg/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[vitaminC], 'mg/day')}
                  show={props.logData[vitaminC] > toxicLevel[vitaminC]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='vitaminC'
                    name='vitaminC'
                    value={props.logData[vitaminC] ? props.logData[vitaminC] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [vitaminC]: e.target.value,
                      });
                      setCookieValue('vitaminC', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Vitamin D</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Total ug</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[vitaminD], 'ug/day')}
                  show={props.logData[vitaminD] > toxicLevel[vitaminD] * props.numDays}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='vitaminD'
                    name='vitaminD'
                    value={props.logData[vitaminD] ? props.logData[vitaminD] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [vitaminD]: e.target.value,
                      });
                      setCookieValue('vitaminD', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Vitamin E</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg mg/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[vitaminE], 'mg/day')}
                  show={props.logData[vitaminE] > toxicLevel[vitaminE]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='vitaminE'
                    name='vitaminE'
                    value={props.logData[vitaminE] ? props.logData[vitaminE] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [vitaminE]: e.target.value,
                      });
                      setCookieValue('vitaminE', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Vitamin K</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg ug/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[vitaminK], 'ug/day')}
                  show={props.logData[vitaminK] > toxicLevel[vitaminK]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='vitaminK'
                    name='vitaminK'
                    value={props.logData[vitaminK] ? props.logData[vitaminK] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [vitaminK]: e.target.value,
                      });
                      setCookieValue('vitaminK', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Calcium</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg mg/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[calcium], 'mg/day')}
                  show={props.logData[calcium] > toxicLevel[calcium]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='calcium'
                    name='calcium'
                    value={props.logData[calcium] ? props.logData[calcium] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [calcium]: e.target.value,
                      });
                      setCookieValue('calcium', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='checkbox_group'>
                <Form.Check
                  id='collagenPeptides'
                  name='collagenPeptides'
                  checked={props.logData[collagenPeptides]}
                  onChange={() => {
                    props.setLogData({
                      ...props.logData,
                      [collagenPeptides]: !props.logData[collagenPeptides],
                    });
                  }}
                  className='checkbox_check'
                />
                <Form.Label>Collagen Peptides</Form.Label>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Dietary Fiber</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg g/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[fiber], 'g/day')}
                  show={props.logData[fiber] > toxicLevel[fiber]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='fiber'
                    name='fiber'
                    value={props.logData[fiber] ? props.logData[fiber] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [fiber]: e.target.value,
                      });
                      setCookieValue('fiber', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Fish Oil/Omega 3</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg mg/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[omega3], 'mg/day')}
                  show={props.logData[omega3] > toxicLevel[omega3]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='omega3'
                    name='omega3'
                    value={props.logData[omega3] ? props.logData[omega3] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [omega3]: e.target.value,
                      });
                      setCookieValue('omega3', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Green Tea</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg {greenTeaUnit}/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[greenTea], `${greenTeaUnit}/day`)}
                  show={props.logData[greenTea] > toxicLevel[greenTea]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='greenTea'
                    name='greenTea'
                    value={props.logData[greenTea] ? props.logData[greenTea] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [greenTea]: e.target.value,
                      });
                      setCookieValue('greenTea', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Iron</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg mg/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[iron], 'mg/day')}
                  show={props.logData[iron] > toxicLevel[iron]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='iron'
                    name='iron'
                    value={props.logData[iron] ? props.logData[iron] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [iron]: e.target.value,
                      });
                      setCookieValue('iron', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Magnesium</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg mg/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[magnesium], 'mg/day')}
                  show={props.logData[magnesium] > toxicLevel[magnesium]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='magnesium'
                    name='magnesium'
                    value={props.logData[magnesium] ? props.logData[magnesium] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [magnesium]: e.target.value,
                      });
                      setCookieValue('magnesium', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Olive Oil</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg {oliveOilUnit}/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[oliveOil], `{oliveOilUnit}/day`)}
                  show={props.logData[oliveOil] > toxicLevel[oliveOil]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='oliveOil'
                    name='oliveOil'
                    value={props.logData[oliveOil] ? props.logData[oliveOil] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [oliveOil]: e.target.value,
                      });
                      setCookieValue('oliveOil', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='checkbox_group'>
                <Form.Check
                  id='probiotics'
                  name='probiotics'
                  checked={props.logData[probiotics]}
                  onChange={() => {
                    props.setLogData({
                      ...props.logData,
                      [probiotics]: !props.logData[probiotics],
                    });
                  }}
                  className='checkbox_check'
                />
                <Form.Label>Probiotics</Form.Label>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Protein Supplements</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg g/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[protein], 'g/day')}
                  show={props.logData[protein] > toxicLevel[protein]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='protein'
                    name='protein'
                    value={props.logData[protein] ? props.logData[protein] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [protein]: e.target.value,
                      });
                      setCookieValue('protein', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
              <div className='cal_select_group'>
                <Form.Label>Zinc</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg mg/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={toxic_tooltip(toxicLevel[zinc], 'mg/day')}
                  show={props.logData[zinc] > toxicLevel[zinc]}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='zinc'
                    name='zinc'
                    value={props.logData[zinc] ? props.logData[zinc] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [zinc]: e.target.value,
                      });
                      setCookieValue('zinc', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='3'>
            <div className='inlineContainer'>
              <Accordion.Header>
                <Form.Text>Sleep</Form.Text>
              </Accordion.Header>
            </div>
            <Accordion.Body>
              <div className='cal_select_group'>
                <Form.Label>Hours</Form.Label>
                <div className='unit-label-container'>
                  <p className='mx-1 mt-1 unit-label'>Avg hours/day</p>
                </div>
                <OverlayTrigger
                  placement='right'
                  overlay={tooltip}
                  show={props.logData[sleep] > 14}
                >
                  <Form.Control
                    style={{ width: '100px' }}
                    type='number'
                    placeholder=''
                    id='sleep'
                    name='sleep'
                    value={props.logData[sleep] ? props.logData[sleep] : ''}
                    className='cal_input_control'
                    onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                    // required
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      props.setLogData({
                        ...props.logData,
                        [sleep]: e.target.value,
                      });
                      setCookieValue('sleep', e.target.value);
                    }}
                  ></Form.Control>
                </OverlayTrigger>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          {props.numDays > 1 ?
              (
                <div className='cal_select_group' style={{margin: '20px'}}>
                  <Form.Label>How many days were you sick during this period?</Form.Label>
                  <div className='unit-label-container'>
                    <p className='mx-1 mt-1 unit-label'>Number of days</p>
                  </div>
                  <OverlayTrigger
                    placement='right'
                    overlay={tooltip}
                    show={props.logData[sickDays] > props.numDays}
                  >
                    <Form.Control
                      style={{ width: '100px' }}
                      type='number'
                      placeholder=''
                      id='sickDays'
                      name='sickDays'
                      value={props.logData[sickDays] || ''}
                      className='cal_input_control'
                      onWheel={(e) => e.target.blur()} // Prevent scroll from changing the input value
                      // required
                      onChange={(e) => {
                        const value = Math.max(0, parseInt(e.target.value || 0, 10));
                        props.setLogData({
                          ...props.logData,
                          sickDays: value,
                          isSick: value > 0, // Update isSick based on sickDays
                        });
                        // console.log('sickDays:', value);
                        setCookieValue('sickDays', e.target.value);
                      }}
                    ></Form.Control>
                  </OverlayTrigger>
                </div>
              ) :
              (
                <div className='checkbox_group'  style={{marginTop: '20px'}}>
                  <Form.Check
                    id='isSick'
                    name='isSick'
                    checked={props.logData[isSick]}
                    onChange={(e) => {
                      props.setLogData({
                        ...props.logData,
                        [isSick]: e.target.checked,
                        [sickDays]: e.target.checked ? 1 : 0,
                      });
                      setCookieValue('sick', e.target.checked);
                      console.log('isSick: ', e.target.checked);
                    }}
                    className='checkbox_check'
                  />
                  <Form.Label>Are you sick today?</Form.Label>
                </div>
              )}
          {showPopup && <PopUpMessage message={"Save successful"} />}
          <Form.Group>
            {(props.grayDates.includes(props.date1) ||
              props.grayDates.includes(props.date2) ||
              props.submitted) && (
              <div className='inlineContainer'>
                <Button type='submit' variant='dark' disabled={showPopup}>
                  Update
                </Button>
              </div>
            )}
            {(props.grayDates.includes(props.date1) ||
              props.grayDates.includes(props.date2) ||
              props.submitted) && (
              <div className='inlineContainer'>
                <p style={{ color: 'gray', fontSize: '14px' }}>
                  (Already submitted today)
                </p>
              </div>
            )}
            {!(
              props.grayDates.includes(props.date1) ||
              props.grayDates.includes(props.date2) ||
              !validRange ||
              props.submitted
            ) && (
              <div className='inlineContainer'>
                <Button type='submit' variant='dark' disabled={showPopup}>
                  Submit
                </Button>
              </div>
            )}
          </Form.Group>
        </Form>
      </Accordion>
    </div>
  );
}

export default LogForm;
