import React, { useMemo, useRef, useEffect } from 'react';
import './Plan.css';
import { Form } from 'react-bootstrap';
import { sendData } from './hooks/Data';
import { useAuthContext } from '../../hooks/useAuthContext';

const Sleep = ({
  currSleep,
  setCurrSleep,
  optSleep,
  setOptSleep,
  newSleep,
  setNewSleep,
  setIsNewData,
  unsubmitValues,
  setUnsubmitValues
}) => {
  const { user } = useAuthContext();
  const userId = useMemo(() => {
    let user = localStorage.getItem('mysUser');
    user = user
      ? JSON.parse(user)
      : { userId: '64ad93a2-a276-43e3-8304-be5fbd587ca1' };
    return user.userId;
  }, []);

  const typingTimer = useRef(null);
  const typingInterval = 2000;

  const prevDiet = useRef({...currSleep});
  
    useEffect(() => {
      prevDiet.current = { ...currSleep };
    }, [currSleep]);

  const handleChange = (e) => {
      let { name, value } = e.target;
      value = Number(value) < 0 ? 0 : Number(value);
      setNewSleep((prevState) => ({ ...prevState, [name]: value }));
      setUnsubmitValues((prevState) => ({ ...prevState, [name]: value}));
    };
  
    const handleBlur = (e) => {
      const { name, value } = e.target;
      setNewSleep((prevState) => ({ ...prevState, [name]: value }));
      typingTimer.current = setTimeout(() => {
        const prevValue = prevDiet.current[name];
        if (value === prevValue) {
          return;
        }
        handleSubmit();
        prevDiet.current = { ...prevDiet.current, [name]: value };
      }, typingInterval);
    };
  
    const handleFocus = (e) => {
      clearTimeout(typingTimer.current);
    };
  
    const handleSubmit = () => {
      if (Object.keys(unsubmitValues).length === 0) {
        return;
      }

      console.log("unsubmitValues", unsubmitValues);
      sendData(unsubmitValues, userId);
      setUnsubmitValues({});
      setIsNewData(true);

    };

  return (
    <section className='my-4 bg bg-secondary p-3 bg-opacity-10 border border-secondary border-1 shadow'>
      <Form.Text>Sleep</Form.Text>
      <div className='d-flex mb-1'>
        {/*Headers */}
        <div className='flex-grow-1 text-start'>
          <br />
        </div>
        <div className='inline heading-label' style={{ marginRight: '13px' }}>
          Current
        </div>
        <div className='inline heading-label' style={{ marginRight: '20px' }}>
          Optimal
        </div>
        <div className='inline heading-label' style={{ marginRight: '10px' }}>
          New
        </div>
      </div>
      <div className='d-flex mb-1'>
        <div className='flex-grow-1 text-start hover-position'>
          <Form.Label className='label-font hover-supplement'>
            Average Hours
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended between 7-9 hours
          </div>
        </div>

        {/*Current*/}
        <div className='form-inline me-2'>
          <Form.Select
            as='select'
            name='currentSleep'
            id='currentSleep'
            className='sleep-box'
            disabled
            onChange={(e) =>
              setCurrSleep({ ...currSleep, sleep: e.target.value })
            }
          >
            <option value={currSleep.sleep} selected>
              {currSleep.sleep}
            </option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
            <option value='7'>7</option>
            <option value='8'>8</option>
            <option value='9'>9</option>
            <option value='10'>10</option>
            <option value='11'>11</option>
            <option value='12'>12</option>
            <option value='13'>13</option>
            <option value='14'>14</option>
          </Form.Select>
        </div>

        {/*Optimal*/}
        <div className='form-inline me-2'>
          <Form.Select
            as='select'
            name='optimalSleep'
            id='optimalSleep'
            className='sleep-box'
            disabled
            onChange={(e) =>
              setOptSleep({ ...optSleep, sleep: e.target.value })
            }
          >
            <option value={optSleep.sleep} selected>
              {optSleep.sleep}
            </option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
            <option value='7'>7</option>
            <option value='8'>8</option>
            <option value='9'>9</option>
            <option value='10'>10</option>
            <option value='11'>11</option>
            <option value='12'>12</option>
            <option value='13'>13</option>
            <option value='14'>14</option>
          </Form.Select>
        </div>

        {/*New*/}
        <div className='form-inline'>
          <Form.Select
            as='select'
            name='sleep'
            id='newSleep'
            className='sleep-box'
            required
            defaultValue=''
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleSubmit}
          >
            <option value=''></option>
            {Array.from({ length: 14 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
    </section>
  );
};

export default Sleep;
