import React from "react";
import { useState, useEffect } from "react";
import "./IntakeForm.css";
import AboutMe from "./Sections/AboutMe";
import FamilyMedical from "./Sections/FamilyMedical";
import MyMedical from "./Sections/MyMedical";
import MyStress from "./Sections/MyStress";
import MySupplement from "./Sections/MySupplements";
import MyDiet from "./Sections/MyDiet";
import MyDiet2 from "./Sections/MyDiet2";
import MyExercise from "./Sections/MyExercise";
import MySleep from "./Sections/MySleep";
import PopUpMessage from "../../components/PopUpMessage";
import {
  getValueByLabel,
  addUserInfoIntoFormData,
  getLifestyleResponseData,
  submitInitialData,
  fetchUserData,
  submitDataToModel,
  UnitConvert,
} from "./actions";

import {
  newExerciseOptionsMap,
  durationOptionsMap,
} from "./Sections/LabelValueMap";

import {
  genderOptions,
  raceOptions,
  educationOptions,
  incomeOptions,
  workingOptions,
  maritalOptions,
  cigarettesOptions,
} from "./Sections/OptionsTable/AboutMeOptions";
import {
  genderLabel,
  dateOfBirthLabel,
  raceLabel,
  weightLabel,
  heightLabel,
  educationLabel,
  incomeLabel,
  workingStatusLabel,
  maritalStatusLabel,
  cigarettesUseLabel,
  formerCigarettesYearsLabel,
  currentCigarettesYearsLabel,
  currentCigarettesPerDayLabel,
  alcoholDrinksPerWeekLabel,
} from "./Sections/Lables/AboutMeLabels";
import {
  DATE_OF_BIRTH,
  RACE,
  GENDER,
  WEIGHT,
  HEIGHT,
  EDUCATION,
  INCOME,
  WORKING_STATUS,
  MARITAL_STATUS,
  CIGARETTES_USE,
  FORMER_CIGARETTES_YEARS,
  CURRENT_CIGARETTES_YEARS,
  CURRENT_CIGARETTES_PER_DAY,
  ALCOHOL_DRINKS_PER_WEEK,
} from "./Sections/NameTable/AboutMeName";

import { familyMedicalHistoryLabel } from "./Sections/Lables/FamilyMedicalLabels";
import { familyMedicalHistoryNames } from "./Sections/NameTable/FamilyMedicalName";
import { familyMedicalHistoryOptions } from "./Sections/OptionsTable/FamilyMedicalOptions";

import { myMedicalHistoryNames } from "./Sections/NameTable/myMedicalName";
import { myMedicalHistoryOptions } from "./Sections/OptionsTable/MyMedicalOptions";
import { myMedicalHistoryLabel } from "./Sections/Lables/myMedicalLabels";

import {
  upsetCountName,
  controlCountName,
  stressCountName,
  confidenceCountName,
  wayCountName,
  copeCountName,
  irritationsCountName,
  topCountName,
  angerCountName,
  difficultiesCountName,
  stressScore,
} from "./Sections/NameTable/MyStressName";
import {
  upsetCountLabel,
  controlCountLabel,
  stressCountLabel,
  confidenceCountLabel,
  wayCountLabel,
  copeCountLabel,
  irritationsCountLabel,
  topCountLabel,
  angerCountLabel,
  difficultiesCountLabel,
} from "./Sections/Lables/MyStressLabels";
import { stressOptions } from "./Sections/OptionsTable/MyStressOptions";

import {
  fiberName,
  proteinName,
  magnesiumName,
  manganeseName,
  phosphorusName,
  potassiumName,
  vitaminAName,
  vitaminCName,
  vitaminDName,
  vitaminKName,
  biotinName,
  chlorideName,
  chromiumName,
  copperName,
  folateFolicAcidName,
  molybdenumName,
  niacinName,
  pantothenicAcidName,
  riboflavinName,
  seleniumName,
  thiamineName,
  cholineName,
  vitaminB6Name,
  vitaminB12Name,
  vitaminEName,
  zincName,
  iodineName,
  ironName,
  nickelName,
  fishOilOmega3Name,
  melatoninName,
  turmericName,
  garlicName,
  matchaGreenTeaName,
  plantSterolsName,
  nmnName,
  nrName,
  calciumAKGName,
  alphaLipoicAcidName,
  quercetinName,
  pqqName,
  hyaluronicAcidName,
  chlorellaName,
  spirulinaName,
  reveratrolName,
  coQ10Name,
  acetylLCarnitineName,
  oliveOilName,
  luteinName,
  milkThistleName,
  spermidineName,
  creatineName,
  trimethylGlycineName,
  calciumName,
  supplementsNames,
} from "./Sections/NameTable/MySupplementName";
import {
  fiberOptions,
  proteinOptions,
  magnesiumOptions,
  manganeseOptions,
  phosphorusOptions,
  potassiumOptions,
  vitaminAOptions,
  vitaminCOptions,
  vitaminDOptions,
  vitaminKOptions,
  biotinOptions,
  chlorideOptions,
  chromiumOptions,
  copperOptions,
  folateFolicAcidOptions,
  molybdenumOptions,
  niacinOptions,
  pantothenicAcidOptions,
  riboflavinOptions,
  seleniumOptions,
  thiamineOptions,
  cholineOptions,
  vitaminB6Options,
  vitaminB12Options,
  vitaminEOptions,
  zincOptions,
  iodineOptions,
  ironOptions,
  nickelOptions,
  fishOilOmega3Options,
  melatoninOptions,
  turmericOptions,
  garlicOptions,
  matchaGreenTeaOptions,
  plantSterolsOptions,
  nmnOptions,
  nrOptions,
  calciumAKGOptions,
  alphaLipoicAcidOptions,
  quercetinOptions,
  pqqOptions,
  hyaluronicAcidOptions,
  chlorellaOptions,
  spirulinaOptions,
  reveratrolOptions,
  coQ10Options,
  acetylLCarnitineOptions,
  oliveOilOptions,
  luteinOptions,
  milkThistleOptions,
  spermidineOptions,
  creatineOptions,
  trimethylGlycineOptions,
  calciumOptions,
  supplementsOptions,
} from "./Sections/OptionsTable/MySupplementOptions";
import {
  fiberDefault,
  proteinDefault,
  magnesiumDefault,
  manganeseDefault,
  phosphorusDefault,
  potassiumDefault,
  vitaminADefault,
  vitaminCDefault,
  vitaminDDefault,
  vitaminKDefault,
  biotinDefault,
  chlorideDefault,
  chromiumDefault,
  copperDefault,
  folateFolicAcidDefault,
  molybdenumDefault,
  niacinDefault,
  pantothenicAcidDefault,
  riboflavinDefault,
  seleniumDefault,
  thiamineDefault,
  cholineDefault,
  vitaminB6Default,
  vitaminB12Default,
  vitaminEDefault,
  zincDefault,
  iodineDefault,
  ironDefault,
  nickelDefault,
  fishOilOmega3Default,
  melatoninDefault,
  turmericDefault,
  garlicDefault,
  matchaGreenTeaDefault,
  plantSterolsDefault,
  nmnDefault,
  nrDefault,
  calciumAKGDefault,
  alphaLipoicAcidDefault,
  quercetinDefault,
  pqqDefault,
  hyaluronicAcidDefault,
  chlorellaDefault,
  spirulinaDefault,
  reveratrolDefault,
  coQ10Default,
  acetylLCarnitineDefault,
  oliveOilDefault,
  luteinDefault,
  milkThistleDefault,
  spermidineDefault,
  creatineDefault,
  trimethylGlycineDefault,
  calciumDefault,
} from "./Sections/OptionsTable/MySupplementDefault";

import { durationOptions } from "./Sections/OptionsTable/MyDietOptions";
import {
  processedMeatLabel,
  poultryLabel,
  redMeatLabel,
  fishLabel,
  refinedGrainLabel,
  wholeGrainLabel,
  vegetablesLabel,
  fruitLabel,
  legumesLabel,
  milkLabel,
  cheeseLabel,
  butterLabel,
  dairyOtherLabel,
} from "./Sections/Lables/MyDiteLabels";
import {
  processedMeatName,
  poultryName,
  redMeatName,
  fishName,
  refinedGrainName,
  wholeGrainName,
  vegetablesName,
  fruitName,
  legumesName,
  dairyName,
  milkName,
  cheeseName,
  butterName,
  dairyOtherName,
  fruitAndVegetableName,
} from "./Sections/NameTable/MyDietName";

import {
  sweetTimeOptions,
  eatingScheduleOptions,
  calorieIntakeOptions,
} from "./Sections/OptionsTable/MyDietOptions";
import {
  sugarySodaLabel,
  sugaryCakesLabel,
  sugaryCookiesLabel,
  sugaryCandyLabel,
  sugaryIceCreamLabel,
  sugaryAddedSugarLabel,
  sugaryOtherLabel,
  artificiallySodaLabel,
  artificiallyCakesLabel,
  artificiallyCookiesLabel,
  artificiallyCandyLabel,
  artificiallyIceCreamLabel,
  artificiallyAddedSugarLabel,
  artificiallyOtherLabel,
  waterIntakeLabel,
  saturatedFatIntakeLabel,
  transFatIntakeLabel,
  intermittentFastingLabel,
  calorieRestrictionLabel,
} from "./Sections/Lables/MyDiteLabels";
import {
  sugarySodaName,
  sugaryCakesName,
  sugaryCookiesName,
  sugaryCandyName,
  sugaryIceCreamName,
  sugaryAddedSugarName,
  sugaryOtherName,
  artificiallySodaName,
  artificiallyCakesName,
  artificiallyCookiesName,
  artificiallyCandyName,
  artificiallyIceCreamName,
  artificiallyAddedSugarName,
  artificiallyOtherName,
  waterIntakeName,
  saturatedFatIntakeName,
  transFatIntakeName,
  intermittentFastingName,
  calorieRestrictionName,
  refinedSugarName,
  artificialSugarName
} from "./Sections/NameTable/MyDietName";

import {
  stretchingLabel,
  boneStrengtheningLabel,
  balanceActivityLabel,
  meditationLabel,
  yogaLabel,
  activitiesSaunaBathingLabel,
  coldExposureLabel,
  lowIntensityLabel,
  moderateIntensityLabel,
  highIntensityLabel,
  cardiovascularSaunaBathingLabel,
  strengthTrainingLabel,
} from "./Sections/Lables/MyExerciseLabels";
import {
  stretchingName,
  boneStrengtheningName,
  balanceActivityName,
  meditationName,
  yogaName,
  activitiesSaunaBathingName,
  coldExposureName,
  lowIntensityName,
  moderateIntensityName,
  highIntensityName,
  cardiovascularSaunaBathingName,
  strengthTrainingName,
} from "./Sections/NameTable/MyExerciseName";
import {
  daysOption,
  minsOptions,
} from "./Sections/OptionsTable/MyExerciseOption";

import {
  bedtimeLabel,
  sleepOnsetLabel,
  wakeUpTimeLabel,
  actualSleepHoursLabel,
  sleepQualityLabel,
  sleepMedicationLabel,
  stayAwakeTroubleLabel,
  enthusiasmLevelLabel,
  fallAsleepDifficultyLabel,
  wakeUpMidnightLabel,
  bathroomNeedsLabel,
  breathingDifficultyLabel,
  loudSnoringLabel,
  coldFeetLabel,
  hotFeetLabel,
  badDreamsLabel,
  painLabel,
} from "./Sections/Lables/MySleepLabels";
import {
  bedtimeName,
  sleepOnsetName,
  wakeUpTimeName,
  actualSleepHoursName,
  sleepQualityName,
  sleepMedicationName,
  stayAwakeTroubleName,
  enthusiasmLevelName,
  fallAsleepDifficultyName,
  wakeUpMidnightName,
  bathroomNeedsName,
  breathingDifficultyName,
  loudSnoringName,
  coldFeetName,
  hotFeetName,
  badDreamsName,
  painName,
  sleepScore,
} from "./Sections/NameTable/MySleepName";
import {
  sleepOptions,
  sleepQualityOptions,
  frequencyOptions,
  enthusiasmLevelOption,
} from "./Sections/OptionsTable/MySleepOptions";

const errorMessage = "Error: Please fill out this field, or enter 0!";

const formDataKey = "IntakeForm";

const formDataUnitKey = "IntakeFormUnit";
const formDataUnitDefaultKey = "IntakeFormUnitDefault";

const currentPageKey = "IntakeFormCurrentPage";

export default function IntakeForm() {
  const [cigaretteStatus, setCigaretteStatus] = useState("");
  
  const [currentPage, setCurrentPage] = useState(() => {
    const savedCurrentPage = sessionStorage.getItem(currentPageKey);
    return savedCurrentPage ? parseInt(savedCurrentPage, 10) : 0;
  });
  // const [formData, setformData] = useState(() => {

  //   const savedData = sessionStorage.getItem(formDataKey);
  //   console.log(savedData);
  //   return savedData ? JSON.parse(savedData) : {};
  // });
  const [formData, setformData] = useState({});
  const [formDataUnit, setformDataUnit] = useState(() => {
    const savedData = sessionStorage.getItem(formDataUnitKey);
    return savedData ? JSON.parse(savedData) : {};
  });
  const [formDataUnitDefault, setformDataUnitDefault] = useState(() => {
    const savedData = sessionStorage.getItem(formDataUnitDefaultKey);
    return savedData ? JSON.parse(savedData) : {};
  });

  const [aboutMe, setAboutMe] = useState({
    [DATE_OF_BIRTH]: null,
    [RACE]: null,
    [GENDER]: null,
    [WEIGHT]: null,
    [HEIGHT]: null,
    [EDUCATION]: null,
    [INCOME]: null,
    [WORKING_STATUS]: null,
    [MARITAL_STATUS]: null,
    [CIGARETTES_USE]: null,
    [FORMER_CIGARETTES_YEARS]: null,
    [CURRENT_CIGARETTES_YEARS]: null,
    [CURRENT_CIGARETTES_PER_DAY]: null,
    [ALCOHOL_DRINKS_PER_WEEK]: null,
  });

  const generateDefaultValues = (nameList) => {
    const initialValues = {};
    nameList.forEach((name) => {
      initialValues[name] = null;
    });
    return initialValues;
  };

  const [familyMedicalHistory, setFamilyMedicalHistory] = useState(
    generateDefaultValues(familyMedicalHistoryNames)
  );

  const [myHistory, setMyHistory] = useState(
    generateDefaultValues(myMedicalHistoryNames)
  );

  const [myStress, setMyStress] = useState({
    [upsetCountName]: null,
    [controlCountName]: null,
    [stressCountName]: null,
    [confidenceCountName]: null,
    [wayCountName]: null,
    [copeCountName]: null,
    [irritationsCountName]: null,
    [topCountName]: null,
    [angerCountName]: null,
    [difficultiesCountName]: null,
  });

  const initialSupplements = {
    [fiberName]: null,
    [proteinName]: null,
    [magnesiumName]: null,
    [manganeseName]: null,
    [phosphorusName]: null,
    [potassiumName]: null,
    [vitaminAName]: null,
    [vitaminCName]: null,
    [vitaminDName]: null,
    [vitaminKName]: null,
    [biotinName]: null,
    [chlorideName]: null,
    [chromiumName]: null,
    [copperName]: null,
    [folateFolicAcidName]: null,
    [molybdenumName]: null,
    [niacinName]: null,
    [pantothenicAcidName]: null,
    [riboflavinName]: null,
    [seleniumName]: null,
    [thiamineName]: null,
    [cholineName]: null,
    [vitaminB6Name]: null,
    [vitaminB12Name]: null,
    [vitaminEName]: null,
    [zincName]: null,
    [iodineName]: null,
    [ironName]: null,
    [nickelName]: null,
    [fishOilOmega3Name]: null,
    [melatoninName]: null,
    [turmericName]: null,
    [garlicName]: null,
    [matchaGreenTeaName]: null,
    [plantSterolsName]: null,
    [nmnName]: null,
    [nrName]: null,
    [calciumAKGName]: null,
    [alphaLipoicAcidName]: null,
    [quercetinName]: null,
    [pqqName]: null,
    [hyaluronicAcidName]: null,
    [chlorellaName]: null,
    [spirulinaName]: null,
    [reveratrolName]: null,
    [coQ10Name]: null,
    [acetylLCarnitineName]: null,
    [oliveOilName]: null,
    [luteinName]: null,
    [milkThistleName]: null,
    [spermidineName]: null,
    [creatineName]: null,
    [trimethylGlycineName]: null,
    [calciumName]: null,
  };

  function addSupplements(initial, newNames) {
    const updatedSupplements = { ...initial };
    newNames.forEach((name) => {
      updatedSupplements[name] = null;
    });
    return updatedSupplements;
  }

  const [mySupplements, setMySupplements] = useState(
    addSupplements(initialSupplements, supplementsNames)
  );

  const [myDiet1, setMyDiet1] = useState({
    [processedMeatName]: null,
    [poultryName]: null,
    [redMeatName]: null,
    [fishName]: null,
    [refinedGrainName]: null,
    [wholeGrainName]: null,
    [fruitAndVegetableName]: null,
    // [vegetablesName]: null,
    // [fruitName]: null,
    [legumesName]: null,
    [dairyName]: null,
    // [milkName]: null,
    // [cheeseName]: null,
    // [butterName]: null,
    // [dairyOtherName]: null,
  });

  const [myDiet2, setMyDiet2] = useState({
    [refinedSugarName]: null,
    [artificialSugarName]: null,
    // [sugarySodaName]: null,
    // [sugaryCakesName]: null,
    // [sugaryCookiesName]: null,
    // [sugaryCandyName]: null,
    // [sugaryIceCreamName]: null,
    // [sugaryAddedSugarName]: null,
    // [sugaryOtherName]: null,
    // [artificiallySodaName]: null,
    // [artificiallyCakesName]: null,
    // [artificiallyCookiesName]: null,
    // [artificiallyCandyName]: null,
    // [artificiallyIceCreamName]: null,
    // [artificiallyAddedSugarName]: null,
    // [artificiallyOtherName]: null,
    [waterIntakeName]: null,
    [saturatedFatIntakeName]: null,
    [transFatIntakeName]: null,
    [intermittentFastingName]: null,
    [calorieRestrictionName]: null,
  });

  const [myExercise, setMyExercise] = useState({
    [stretchingName]: null,
    [boneStrengtheningName]: null,
    [balanceActivityName]: null,
    [meditationName]: null,
    [yogaName]: null,
    [activitiesSaunaBathingName]: null,
    [coldExposureName]: null,
    [lowIntensityName]: null,
    [moderateIntensityName]: null,
    [highIntensityName]: null,
    [cardiovascularSaunaBathingName]: null,
    [strengthTrainingName]: null,
  });

  const [mySleep, setMySleep] = useState({
    [bedtimeName]: null,
    [sleepOnsetName]: null,
    [wakeUpTimeName]: null,
    [actualSleepHoursName]: null,
    [sleepQualityName]: null,
    [sleepMedicationName]: null,
    [stayAwakeTroubleName]: null,
    [enthusiasmLevelName]: null,
    [fallAsleepDifficultyName]: null,
    [wakeUpMidnightName]: null,
    [bathroomNeedsName]: null,
    [breathingDifficultyName]: null,
    [loudSnoringName]: null,
    [coldFeetName]: null,
    [hotFeetName]: null,
    [badDreamsName]: null,
    [painName]: null,
  });

  const [limit, setLimit] = useState({
    [fiberName]: 70,
    [proteinName]: 350,
    [magnesiumName]: 350,
    [manganeseName]: 11,
    [phosphorusName]: 4000,
    [potassiumName]: 7000,
    [vitaminAName]: 3000,
    [vitaminCName]: 2000,
    [vitaminDName]: 100,
    [vitaminKName]: 250,
    [biotinName]: 1000,
    [chlorideName]: 3600,
    [chromiumName]: 70,
    [copperName]: 10,
    [folateFolicAcidName]: 1000,
    [molybdenumName]: 2000,
    [niacinName]: 35,
    [pantothenicAcidName]: 5,
    [riboflavinName]: 500,
    [seleniumName]: 400,
    [thiamineName]: 500,
    [cholineName]: 3500,
    [vitaminB6Name]: 100,
    [vitaminB12Name]: 500,
    [vitaminEName]: 1000,
    [zincName]: 40,
    [iodineName]: 1100,
    [ironName]: 45,
    [nickelName]: 280,
    [fishOilOmega3Name]: 5000,
    [melatoninName]: 50,
    [turmericName]: 5000,
    [matchaGreenTeaName]: 2300,
    [plantSterolsName]: 4000,
    [nmnName]: 500,
    [nrName]: 1000,
    [calciumAKGName]: 1000,
    [alphaLipoicAcidName]: 1500,
    [quercetinName]: 1000,
    [pqqName]: 80,
    [hyaluronicAcidName]: 400,
    [chlorellaName]: 1000,
    [spirulinaName]: 3000,
    [reveratrolName]: 2000,
    [coQ10Name]: 1200,
    [acetylLCarnitineName]: 3000,
    [oliveOilName]: 77,
    [luteinName]: 20,
    [milkThistleName]: 600,
    [spermidineName]: 50,
    [creatineName]: 30,
    [trimethylGlycineName]: 6000,
    [calciumName]: 2500,
    [processedMeatName]: 6 * 7,
    [poultryName]: 6 * 7,
    [redMeatName]: 6 * 7,
    [fishName]: 6 * 7,
    [refinedGrainName]: 15 * 7,
    [wholeGrainName]: 15 * 7,
    [vegetablesName]: 10,
    [fruitName]: 10,
    [legumesName]: 4 * 7,
    [milkName]: 6,
    [cheeseName]: 0.25,
    [butterName]: 0.25,
    [saturatedFatIntakeName]: 50,
    [transFatIntakeName]: 50,
    [waterIntakeName]: 8000,
    [sugarySodaName]: 10,
    [sugaryCakesName]: 5,
    [sugaryCookiesName]: 10,
    [sugaryCandyName]: 20,
    [sugaryIceCreamName]: 5,
    [sugaryAddedSugarName]: 36,
    [artificiallySodaName]: 10,
    [artificiallyCakesName]: 5,
    [artificiallyCookiesName]: 10,
    [artificiallyCandyName]: 20,
    [artificiallyIceCreamName]: 5,
    [artificiallyAddedSugarName]: 36,
  });

  const [heightUnit, setHeightUnit] = useState("in");
  const [weightUnit, setWeightUnit] = useState("lbs");
  const [waterUnit, setWaterUnit] = useState("oz");
  const [submittedPlan, setSubmittedPlan] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const [unitDefault, setUnitDefault] = useState({
    [fiberName]: fiberOptions[0],
    [magnesiumName]: magnesiumOptions[0],
    [fishOilOmega3Name]: fishOilOmega3Options[0],
    [matchaGreenTeaName]: matchaGreenTeaOptions[0],
    [acetylLCarnitineName]: acetylLCarnitineOptions[0],
    [oliveOilName]: oliveOilOptions[0],
    [vitaminEName]: vitaminEOptions[0],
    [trimethylGlycineName]: trimethylGlycineOptions[0],
    [calciumName]: calciumOptions[0],
    [coQ10Name]: coQ10Options[0],

    // [sugarySodaName]: sweetTimeOptions[0],
    // [sugaryCakesName]: sweetTimeOptions[0],
    // [sugaryCookiesName]: sweetTimeOptions[0],
    // [sugaryCandyName]: sweetTimeOptions[0],
    // [sugaryIceCreamName]: sweetTimeOptions[0],
    // [sugaryAddedSugarName]: sweetTimeOptions[0],
    // [sugaryOtherName]: sweetTimeOptions[0],
    // [artificiallySodaName]: sweetTimeOptions[0],
    // [artificiallyCakesName]: sweetTimeOptions[0],
    // [artificiallyCookiesName]: sweetTimeOptions[0],
    // [artificiallyCandyName]: sweetTimeOptions[0],
    // [artificiallyIceCreamName]: sweetTimeOptions[0],
    // [artificiallyAddedSugarName]: sweetTimeOptions[0],
    // [artificiallyOtherName]: sweetTimeOptions[0],
  });

  const [unit, setUnit] = useState({
    [fiberName]: fiberOptions[0],
    [magnesiumName]: magnesiumOptions[0],
    [fishOilOmega3Name]: fishOilOmega3Options[0],
    [matchaGreenTeaName]: matchaGreenTeaOptions[0],
    [acetylLCarnitineName]: acetylLCarnitineOptions[0],
    [oliveOilName]: oliveOilOptions[0],
    [vitaminEName]: vitaminEOptions[0],
    [trimethylGlycineName]: trimethylGlycineOptions[0],
    [calciumName]: calciumOptions[0],
    [coQ10Name]: coQ10Options[0],

    // [sugarySodaName]: sweetTimeOptions[0],
    // [sugaryCakesName]: sweetTimeOptions[0],
    // [sugaryCookiesName]: sweetTimeOptions[0],
    // [sugaryCandyName]: sweetTimeOptions[0],
    // [sugaryIceCreamName]: sweetTimeOptions[0],
    // [sugaryAddedSugarName]: sweetTimeOptions[0],
    // [sugaryOtherName]: sweetTimeOptions[0],
    // [artificiallySodaName]: sweetTimeOptions[0],
    // [artificiallyCakesName]: sweetTimeOptions[0],
    // [artificiallyCookiesName]: sweetTimeOptions[0],
    // [artificiallyCandyName]: sweetTimeOptions[0],
    // [artificiallyIceCreamName]: sweetTimeOptions[0],
    // [artificiallyAddedSugarName]: sweetTimeOptions[0],
    // [artificiallyOtherName]: sweetTimeOptions[0],
  });

  const stressNames = [
    upsetCountName,
    controlCountName,
    stressCountName,
    confidenceCountName,
    wayCountName,
    copeCountName,
    irritationsCountName,
    topCountName,
    angerCountName,
    difficultiesCountName,
  ];

  const sleepNames = [
    fallAsleepDifficultyName,
    wakeUpMidnightName,
    bathroomNeedsName,
    breathingDifficultyName,
    loudSnoringName,
    badDreamsName,
    painName,
  ];

  // const cardioNames = [
  //   lowIntensityName,
  //   moderateIntensityName,
  //   highIntensityName,
  //   cardiovascularSaunaBathingName,
  //   strengthTrainingName,
  // ];
  // const cardioMaps = {
  //   0: 0,
  //   1: 15,
  //   2: 45,
  //   3: 90,
  //   4: 150,
  //   5: 180,
  // };
  useEffect(() => {
    const fetchDataFromDB = async (userId) => {
      try {
        const data = await getLifestyleResponseData(userId);

        saveDatafromSessionToUseState(data, aboutMe, setAboutMe);
        saveDatafromSessionToUseState(
          data,
          familyMedicalHistory,
          setFamilyMedicalHistory
        );
        saveDatafromSessionToUseState(data, myHistory, setMyHistory);
        saveDatafromSessionToUseState(data, myStress, setMyStress);
        saveDatafromSessionToUseState(data, mySupplements, setMySupplements);
        saveDatafromSessionToUseState(data, myDiet1, setMyDiet1);
        saveDatafromSessionToUseState(data, myDiet2, setMyDiet2);
        saveDatafromSessionToUseState(data, myExercise, setMyExercise, true);
        saveDatafromSessionToUseState(data, mySleep, setMySleep);
        setSubmittedPlan(data.data.submitted_plan);
        if (submittedPlan) {
          setFirstSubmit(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchUData = async (userId) => {
      try {
        const data = await fetchUserData(userId);
        if (data.preferred_unit_system === "metric") {
          setWeightUnit("kg");
          setHeightUnit("cm");
          setWaterUnit("ml");
        } else {
          setWeightUnit("lbs");
          setHeightUnit("in");
          setWaterUnit("oz");
          // console.log(matchaGreenTeaOptions[1]);
          // setUnit({ ...unit, [matchaGreenTeaName]: matchaGreenTeaOptions[1] });
          // const newWaterLimit = Math.round(limit[waterIntakeName] * 29.5735);
          // setLimit({ ...limit, [waterIntakeName]: newWaterLimit });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    const userId = JSON.parse(localStorage.getItem("mysUser")).userId;
    const setData = fetchDataFromDB(userId);
    fetchUData(userId);
  }, []);



  const saveDatafromSessionToUseState = async (
    data,
    myState,
    setMyState,
    exerciseSignal
  ) => {
    let newValue = { ...myState };
    Object.keys(myState).forEach((key) => {
      if (data.data.hasOwnProperty(key)) {
        newValue[key] = data.data[key];
      }
    });

    // if (exerciseSignal) {
    //   cardioNames.forEach((name, index) => {
    //     const value = data.data[name];
    //     const key = Object.keys(cardioMaps).find(
    //       (key) => cardioMaps[key] === value
    //     );
    //     newValue = { ...newValue, [name]: key };
    //   });
    // }

    setMyState(newValue);
  };

  useEffect(() => {
    sessionStorage.setItem(formDataKey, JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    sessionStorage.setItem(formDataUnitKey, JSON.stringify(formDataUnit));
  }, [formDataUnit]);

  useEffect(() => {
    sessionStorage.setItem(
      formDataUnitDefaultKey,
      JSON.stringify(formDataUnitDefault)
    );
  }, [formDataUnitDefault]);

  const handleSumbit = () => {
    let newErrors = {};
    newErrors = checkEmpty(currentPage, newErrors);
    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors);
      return;
    }
    setErrors(newErrors);
    setSubmittedPlan(1);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
      setFirstSubmit(false);
    }, 2000);
    
    const userInfo = JSON.parse(localStorage.getItem("mysUser"));
    const submitData = {
      ...userInfo,
      ...aboutMe,
      ...familyMedicalHistory,
      ...myHistory,
      ...myStress,
      ...mySupplements,
      ...myDiet1,
      ...myDiet2,
      ...myExercise,
      ...mySleep,
    };

    let stressValue = 0;
    let sleepValue = 0;

    stressNames.forEach((name, index) => {
      stressValue = stressValue + submitData[name];
    });
    stressValue = stressValue < 15 ? 2 : stressValue < 29 ? 1 : 0;
    sleepNames.forEach((name, index) => {
      sleepValue = stressValue + submitData[name];
    });
    sleepValue = sleepValue < 8 ? 0 : sleepValue < 15 ? 1 : 2;
    submitData[stressScore] = stressValue;
    submitData[sleepScore] = sleepValue;

    // cardioNames.forEach((name, index) => {
    //   let val = cardioMaps[submitData[name]];
    //   submitData[name] = val;
    // });

    for (const key in unit) {
      if (unit[key] !== unitDefault[key]) {
        const newValue = UnitConvert(
          submitData[key],
          unit[key],
          unitDefault[key],
          key
        );
        submitData[key] = newValue;
      }
    }
    console.log(submitData);
    submitInitialData(submitData);
  };

  const [errors, setErrors] = useState({});

  const checkEmpty = (current, errors) => {
    switch (current) {
      case 0:
        const fieldToSkip = [];
        // console.log(aboutMe[CIGARETTES_USE]);
        if (aboutMe[CIGARETTES_USE] == "0") {
          // console.log('never used');
          fieldToSkip.push(
            FORMER_CIGARETTES_YEARS,
            CURRENT_CIGARETTES_YEARS,
            CURRENT_CIGARETTES_PER_DAY
          );
        } else if (aboutMe[CIGARETTES_USE] == "1") {
          // console.log('former user');
          fieldToSkip.push(CURRENT_CIGARETTES_PER_DAY);
        }
        Object.entries(aboutMe).forEach(([key, value]) => {
          if (value !== 0) {
            if (!value && !fieldToSkip.includes(key)) {
              errors[key] = errorMessage;
              // console.log(key + ' is not filled out');
              // console.log(key + ': ' + value)
            }
          }
        });
        break;
      case 1:
        // Object.entries(familyMedicalHistory).forEach(([key, value]) => {
        //   if (value !== 0) {
        //     if (!value) {
        //         errors[key] = errorMessage;
        //         console.log(key + ' is not filled out');
        //         console.log(key + ': ' + value)
        //     }
        //   }
        // })
        break;
      case 2:
        // Object.entries(myHistory).forEach(([key, value]) => {
        //   if (value !== 0) {
        //     if (!value) {
        //         errors[key] = errorMessage;
        //         console.log(key + ' is not filled out');
        //         console.log(key + ': ' + value)
        //     }
        //   }
        // })
        break;
      case 3:
        Object.entries(myStress).forEach(([key, value]) => {
          if (value !== 0) {
            if (!value) {
              errors[key] = errorMessage;
              // console.log(key + ' is not filled out');
              // console.log(key + ': ' + value)
            }
          }
        });
        break;
      case 4:
        Object.entries(mySupplements).forEach(([key, value]) => {
          if (supplementsOptions.hasOwnProperty(key)) {
          } else {
            if (value !== 0) {
              if (!value) {
                errors[key] = errorMessage;
                // console.log(key + ' is not filled out');
                // console.log(key + ': ' + value)
              }
            }
          }
        });
        // console.log(supplementsNames);
        supplementsNames.forEach((supplement) => {
          if (errors.hasOwnProperty(supplement)) {
            delete errors[supplement];
          }
        });
        break;
      case 5:
        Object.entries(myDiet1).forEach(([key, value]) => {
          if (value !== 0) {
            if (!value) {
              errors[key] = errorMessage;
              // console.log(key + ' is not filled out');
              // console.log(key + ': ' + value)
            }
          }
        });
        break;
      case 6:
        Object.entries(myDiet2).forEach(([key, value]) => {
          if (value !== 0) {
            if (!value) {
              errors[key] = errorMessage;
              // console.log(key + ' is not filled out');
              // console.log(key + ': ' + value)
            }
          }
        });
        break;
      case 7:
        Object.entries(myExercise).forEach(([key, value]) => {
          if (value !== 0) {
            if (!value) {
              errors[key] = errorMessage;
              // console.log(key + ' is not filled out');
              // console.log(key + ': ' + value)
            }
          }
        });
        break;
      case 8:
        Object.entries(mySleep).forEach(([key, value]) => {
          if (value !== 0) {
            if (!value) {
              errors[key] = errorMessage;
              // console.log(key + ' is not filled out');
              // console.log(key + ': ' + value)
            }
          }
        });
        break;
      default:
        break;
    }
    return errors;
  };

  // const handleChangePage = (nextPage) => {
  //   let newErrors = {};
  //   newErrors = checkEmpty(currentPage, newErrors);
  //   if (Object.keys(newErrors).length === 0) {
  //     if (nextPage) {
  //       setCurrentPage(currentPage + 1);
  //     } else {
  //       setCurrentPage(currentPage - 1);
  //     }
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //     setErrors({});
  //   } else {
  //     setErrors(newErrors);
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   }
  // };


  const calculateAge = (dob) => {
    if (!dob) return null;
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const userAge = calculateAge(aboutMe[DATE_OF_BIRTH]);

  // Calculate yesterday's date to restrict future dates
  const today = new Date();
  today.setDate(today.getDate() - 1);  // Exclude today
  const maxDate = today.toISOString().split("T")[0];  // Format as YYYY-MM-DD

  const [showSavePopup, setSaveShowPopup] = useState(false);
  const [isGoingBack, setIsGoingBack] = useState(false);
  const [hasChanges, setHasChanges] = useState(false); // New state to track changes
  
  const handleChangePage = (nextPage) => {
    let newErrors = checkEmpty(currentPage, {});
  
    if (Object.keys(newErrors).length === 0) {
      if (hasChanges && !firstSubmit) { // Show Save Changes popup only if it's not the first submit
        setSaveShowPopup(true);
        setIsGoingBack(!nextPage); // Set to true if going back, false if going forward
      } else {
        // Move to the next or previous page directly if no changes or first submit
        setCurrentPage(nextPage ? currentPage + 1 : currentPage - 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else {
      setErrors(newErrors);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  
  const handleConfirmNext = (saveChanges) => {
    if (saveChanges && !firstSubmit) {
      handleSumbit(); // Save changes if confirmed and not first submit
    }
  
    // Close the popup
    setSaveShowPopup(false);
    setErrors({}); // Clear errors after navigating
    setHasChanges(false); // Reset change tracking
  
    // Move to the next or previous page based on isGoingBack flag
    if (isGoingBack) {
      setCurrentPage(currentPage - 1); // Move to the previous page
    } else {
      setCurrentPage(currentPage + 1); // Move to the next page
    }
  
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const savedCurrentPage = sessionStorage.getItem('currentPageKey');
    if (savedCurrentPage !== null) {
      setCurrentPage(parseInt(savedCurrentPage, 10));
    }
  }, []);

  const handleButtonClick = () => {
    sessionStorage.setItem('currentPageKey', currentPage); // Save current page to sessionStorage
    window.location.reload(); // Refresh the page
  };

  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <div className="questionnaire_title">Intake Form</div>
      <div
        class="tab-pane fade show active"
        id="intake"
        role="tabpanel"
        aria-labelledby="home-tab"
        ng-controller="calorieCalculator"
      >
        <div class="row">
          <div class="col-xs-12 col-md-8 offset-md-2">
            {currentPage === 0 && (
              <AboutMe
                formData={formData}
                setformData={setformData}
                heightUnit={heightUnit}
                weightUnit={weightUnit}
                dataState={aboutMe}
                setDataState={setAboutMe}
                errors={errors}
                maxDate={maxDate}  // Pass maxDate to restrict future dates
                setHasChanges={setHasChanges} // Pass the function
              />
            )}
            {currentPage === 1 && (
              <FamilyMedical
                formData={formData}
                dataState={familyMedicalHistory}
                setDataState={setFamilyMedicalHistory}
                errors={errors}
                setHasChanges={setHasChanges} // Pass the function
              />
            )}
            {currentPage === 2 && (
              <MyMedical
                formData={formData}
                dataState={myHistory}
                setDataState={setMyHistory}
                errors={errors}
                setHasChanges={setHasChanges} // Pass the function
              />
            )}
            {currentPage === 3 && (
              <MyStress
                formData={formData}
                dataState={myStress}
                setDataState={setMyStress}
                errors={errors}
                setHasChanges={setHasChanges} // Pass the function
              />
            )}
            {currentPage === 4 && (
              <MySupplement
                formData={formData}
                dataState={mySupplements}
                setDataState={setMySupplements}
                unitState={unit}
                setUnitState={setUnit}
                errors={errors}
                limit={limit}
                setLimit={setLimit}
                setHasChanges={setHasChanges} // Pass the function
              />
            )}
            {currentPage === 5 && (
              <MyDiet
                formData={formData}
                dataState={myDiet1}
                setDataState={setMyDiet1}
                errors={errors}
                limit={limit}
                setLimit={setLimit}
                setHasChanges={setHasChanges} // Pass the function
              />
            )}
            {currentPage === 6 && (
              <MyDiet2
                formData={formData}
                dataState={myDiet2}
                setDataState={setMyDiet2}
                unitState={unit}
                setUnitState={setUnit}
                errors={errors}
                waterUnit={waterUnit}
                limit={limit}
                setLimit={setLimit}
                setHasChanges={setHasChanges} // Pass the function
              />
            )}
            {currentPage === 7 && (
              <MyExercise
                formData={formData}
                dataState={myExercise}
                setDataState={setMyExercise}
                errors={errors}
                setHasChanges={setHasChanges} // Pass the function
              />
            )}
            {currentPage === 8 && (
              <MySleep
                formData={formData}
                dataState={mySleep}
                setDataState={setMySleep}
                errors={errors}
                setHasChanges={setHasChanges} // Pass the function
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="navigation-buttons"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <button
          className="btn btn-primary"
          disabled={currentPage === 0}
          onClick={() => handleChangePage(false)}
        >
          Previous
        </button>
        {currentPage !== 8 && (
          <button
            className="btn btn-primary"
            onClick={() => handleChangePage(true)}
          >
            Next
          </button>
        )}
      </div>
      
      {/* Popup Confirmation */}
      {showSavePopup && !firstSubmit && (
        <div className="popup-overlay">
          <div className="popup">
            {/* Close button in the top-right corner */}
            <button 
              className="close-button" 
              onClick={() => {
                handleButtonClick();
              }}
            >
              &times;
            </button>
            
            <p>Do you want to save changes before navigating away?</p>
            <div className="popup-buttons">
              <button
                className="btn btn-primary"
                onClick={() => handleConfirmNext(true)} // Save changes
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}

    {showPopup && <PopUpMessage message='Update successful' />}
      <div className="inlineContainer">
      {!submittedPlan && firstSubmit ? (
        <button 
              className="btn btn-primary"
              disabled={(!submittedPlan && currentPage !== 8)}
              onClick={() => {
                handleSumbit();
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
          >
              {!firstSubmit ? 'Saved' : submittedPlan ? 'Already Submitted' : currentPage === 8 ? 'Submit' : 'Please finish form first'}
        </button>
      ) : (
        <button 
              className="btn btn-primary"
              onClick={() => {
                handleSumbit();
              }}
          >
          Update
        </button>
      )}
      </div>
      {userAge !== null && userAge < 18 && (
        <p style={{ textAlign: "center" }}>
          Note: For individuals below the age of 18 years, we can record what
          they do, but we don't estimate what their disease risks are or how
          much lifespan or disease risks will change.
        </p>
      )}
    </div>
  );
}