import React, { useState, useEffect, useId, useRef } from "react";
import axios from "axios";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, Link } from "react-router-dom";
import "./AccountSettings.css"; // Import your CSS file
import { fetchUserData } from "../MyPlan/hooks/Data";
import PaymentUpdate from "../Organizations/PaymentUpdate";
import moment from "moment-timezone"; // Import moment-timezone for timezone handling

const AccountSettings = () => {
  const { user, dispatch } = useAuthContext();
  const [preferredUnitSystem, setPreferredUnitSystem] = useState("imperial");
  // const [preferredUnitSystem, setPreferredUnitSystem] = useState("imperial");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); //navigate to organizations manager

  const baseURLAWS = process.env.REACT_APP_baseUrl;
  //let baseURLAWS = "https://api.myyouthspan.com";
  //const baseURLAWS = process.env.REACT_APP_localBaseUrlAWS;

  const [orgData, setOrgData] = useState(null);
  const [familyData, setFamilyData] = useState(null);
  const [fetchedData, setFetchedData] = useState(false);
  const userId = JSON.parse(localStorage.getItem('mysUser')).userId;

  const [preferredTimezone, setPreferredTimezone] = useState(moment.tz.guess()); // State for preferred timezone
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showAllTimezones, setShowAllTimezones] = useState(false);
  const [updateMessage, setUpdateMessage] = useState(""); // State for update message
  const dropdownRef = useRef(null);
  const [timezoneSearch, setTimezoneSearch] = useState(""); // State for timezone search

  const getOrgData = (orgtype) => {
    axios
      .get(
        baseURLAWS +
          "api/organizations/orginfo/" +
          user.userId +
          "/" +
          orgtype,
      )
      .then((response) => {
        if (orgtype === "F") {
          setFamilyData(response.data);
        } else if (orgtype === "C") {
          setOrgData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching family/organization data: ", error);
      })
      .finally(() => {
        setFetchedData(true);
      });
  };

  useEffect(() => {
    if (user) {
      getOrgData("F");
      getOrgData("C");
    }
  }, [user]);

  useEffect(() => {
    const fetchUData = async (userId) => {
      console.log('fetchUData')
      try {
        const data = await fetchUserData(userId);
        console.log(userId);
        console.log(data.preferredUnitSystem);
        if (data.preferred_unit_system === 'imperial') {
          setPreferredUnitSystem('imperial')
        } else {
          setPreferredUnitSystem('metric')
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUData(userId);
  }, []);

  const handleUnitSystemChange = (event) => {
    setPreferredUnitSystem(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // const csrfToken = getCookie("csrftoken")
    // const jwtToken = localStorage.getItem("jwt").replace(/^"(.*)"$/, "$1");
    // const jwtToken = localStorage.getItem("jwt").replace(/^"(.*)"$/, "$1");
    setLoading(true);

    // Set up the request endpoint
    const accountSettingsUrl = baseURLAWS + "api/users/account-settings/";
    // const userId = JSON.parse(localStorage.getItem('mysUser')).userId;
    // console.log(jwtToken);
    // console.log(`Bearer ${jwtToken}`);
    // Update the preferred unit system
    axios
      .put(
        accountSettingsUrl,
        { "userId": userId,
          "preferred_unit_system": preferredUnitSystem,
          "preferred_timezone": preferredTimezone },
        // {
        //   headers: {
        //     Authorization: `Bearer ` + jwtToken,
        //     // "X-CSRFToken": csrfToken,
        //   },
        // },
        { "userId": userId,
          "preferred_unit_system": preferredUnitSystem,
          "preferred_timezone": preferredTimezone },
        // {
        //   headers: {
        //     Authorization: `Bearer ` + jwtToken,
        //     // "X-CSRFToken": csrfToken,
        //   },
        // },
      )
      .then((response) => {
        // Handle success
        setLoading(false);
        console.log(response.data);
        dispatch({ type: "UPDATE_PREFERRED_UNIT_SYSTEM", preferredUnitSystem });
        dispatch({ type: "UPDATE_PREFERRED_TIMEZONE", preferredTimezone });
      })
      .catch((error) => {
        // Handle error
        setLoading(false);
      });
  };

  //navigate buttons
  const handleFamilyPlan = () => {
    navigate("/familyplan");
  };
  const handleOrganizationPlan = () => {
    navigate("/organizationplan");
  };

  const handleLeave = (userid, orgtype) => {
    axios
      .post(baseURLAWS + "api/organizations/leave", {
        user_id: userid,
        orgtype: orgtype,
      })
      .then(() => {
        if (orgtype === "F") {
          setFamilyData(null);
        } else if (orgtype === "C") {
          setOrgData(null);
        }
      })
      .catch((error) => {
        console.error("Error leaving org/family plan: ", error);
      });
  };

  const handleCreateFamily = () => {
    axios
      .post(baseURLAWS + "api/organizations/createfamily", {
        user_id: user.userId,
      })
      .then((response) => {
        console.log("Response: ", response.data);
        navigate("/familyplan");
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const handlePaymentUpdateClick = () => {
    navigate('/payment_update'); // Navigate to the payment update screen
  };

  const handleTimezoneInputClick = () => {
    setIsDropdownOpen(true); // Open the dropdown when the input is clicked
    setShowAllTimezones(false); // Ensure "Other" options are not shown when reopening
    setTimezoneSearch(""); // Clear the search input when reopening the dropdown
  };

  const handleOtherOptionClick = () => {
    setShowAllTimezones((prev) => !prev); // Toggle showing all timezones
  };

  const handleTimezoneSelect = (tz) => {
    setPreferredTimezone(tz); // Set the selected timezone
    setIsDropdownOpen(false); // Close the dropdown
    setShowAllTimezones(false); // Reset to not show all timezones
  };

  const timezoneOptions = moment.tz.names().map(tz => {
    const offset = moment.tz(tz).format('Z');
    return {
      value: tz,
      label: `(GMT${offset}) ${tz.replace(/_/g, ' ')}`
    };
  });

  // Group timezones by continent
  const groupedTimezones = timezoneOptions.reduce((acc, tz) => {
    const continent = tz.value.split("/")[0]; // Get the continent from the timezone string
    if (!acc[continent]) {
      acc[continent] = []; // Initialize the continent array if it doesn't exist
    }
    acc[continent].push(tz); // Push the timezone into the corresponding continent array
    return acc;
  }, {});

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close the dropdown
        setShowAllTimezones(false); // Reset to not show all timezones
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Update Timezone Button
  const updateTimezone = () => {
    const timezoneUrl = process.env.REACT_APP_baseUrl + "api/users/preferred-timezone/";
    axios.put(timezoneUrl, {
      userId: user.userId, // Ensure this is a valid user ID
      preferred_timezone: preferredTimezone // Include preferred timezone in the submission
    })
    .then(response => {
      console.log(response.data);
      dispatch({ type: "UPDATE_PREFERRED_TIMEZONE", preferredTimezone }); // Dispatch preferred timezone update
      setUpdateMessage("Preferred Timezone is Updated"); // Set the update message
      setTimeout(() => setUpdateMessage(""), 3000); // Clear message after 3 seconds
    })
    .catch(error => {
      console.error("Error updating preferred timezone:", error.response ? error.response.data : error.message);
    });
  };

  if (loading || !user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="account-settings-container">
      <br />
      <br />
      <h2 className="account-settings-header">Account Settings</h2>
      <p>Email: {user.email}</p>
      <p>Username: {user.firstName}</p>

      {user.isPremium === true && orgData ? (
        orgData.is_manager ? (
          <button
            className="account-settings-button"
            onClick={handleOrganizationPlan}
          >
            View Organization
          </button>
        ) : (
          <button
            className="account-settings-button"
            onClick={() => handleLeave(user.userId, "C")}
          >
            Leave Organization
          </button>
        )
      ) : null}

      {user.isPremium === true && fetchedData ? (
        familyData ? (
          familyData.is_manager ? (
            <button
              className="account-settings-button"
              onClick={handleFamilyPlan}
            >
              View Family Plan
            </button>
          ) : (
            <button
              className="account-settings-button"
              onClick={() => handleLeave(user.userId, "F")}
            >
              Unsubscribe Family Plan
            </button>
          )
        ) : (
          <button
            className="account-settings-button"
            onClick={handleCreateFamily}
          >
            Create Family Plan
          </button>
        )
      ) : null}

      <form onSubmit={handleSubmit}>
        <div className="account-settings-field">
          <label className="account-settings-label">
            Preferred Unit System:
          </label>
          <select
            className="account-settings-select"
            value={preferredUnitSystem}
            onChange={handleUnitSystemChange}
          >
            <option value="imperial">Imperial</option>
            {/* <option value="metric" >Metric</option> */}
          </select>
        </div>
        <button className="account-settings-button" type="submit" disabled>
          Update Settings
        </button>
      </form> 
      

      <div className="account-settings-field">
        <label className="account-settings-label">Preferred Timezone:</label>
        <div className="timezone-input" onClick={handleTimezoneInputClick} style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '4px', cursor: 'pointer' }}>
          {preferredTimezone} {/* Display the selected timezone */}
        </div>
        {isDropdownOpen && (
          <div className="timezone-dropdown" ref={dropdownRef}>
            <div
              className={`timezone-option ${preferredTimezone === moment.tz.guess() ? 'selected-timezone' : ''}`} // Add selected class if guessed timezone is selected
              onClick={() => handleTimezoneSelect(moment.tz.guess())} // Make guessed timezone selectable
              style={{ backgroundColor: preferredTimezone === moment.tz.guess() ? '#d3d3d3' : '#f0f0f0', cursor: 'pointer' }} // Gray background if selected, light gray otherwise
            >
              {`(GMT${moment.tz(moment.tz.guess()).format('Z')}) ${moment.tz.guess().replace(/_/g, ' ')}`} {/* Show guessed timezone */}
            </div>
            <div
              className={`timezone-option ${showAllTimezones ? 'selected-timezone' : ''}`} // Add selected class if "Other" is selected
              onClick={handleOtherOptionClick} // Click handler for "Other"
              style={{ cursor: 'pointer', fontWeight: 'bold', color: 'black' }} // Bold and black text for "Other"
            >
              Other
            </div>
            {showAllTimezones && (
              <div>
                <input
                  className="account-settings-input"
                  type="text"
                  placeholder="Search other timezones..."
                  value={timezoneSearch} // Bind the search input to the state
                  onChange={(e) => setTimezoneSearch(e.target.value)} // Update search state
                />
                {Object.entries(groupedTimezones).map(([continent, timezones]) => {
                  // Filter timezones based on the search input
                  const filteredTimezones = timezones.filter(tz => tz.label.toLowerCase().includes(timezoneSearch.toLowerCase()));
                  return filteredTimezones.length > 0 && (
                    <div key={continent}>
                      <strong>{continent}</strong>
                      {filteredTimezones.map((tz) => (
                        <div
                          key={tz.value}
                          className={`timezone-option ${tz.value === preferredTimezone ? 'selected-timezone' : ''}`} // Add selected class if timezone is selected
                          onClick={() => handleTimezoneSelect(tz.value)} // Select timezone
                          style={{
                            backgroundColor: tz.value === preferredTimezone ? '#d3d3d3' : 'transparent', // Gray background if selected
                            cursor: 'pointer', // Change cursor to pointer
                          }}
                        >
                          {tz.label}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>

      <button 
          className="account-settings-button" 
          type="button" 
          onClick={updateTimezone}
          style={{ marginBottom: '20px' }} // Add margin for spacing
        >
          Update Timezone
        </button>
  
      
      <button className="update-payment-button" type="submit" onClick={handlePaymentUpdateClick}>
        Update Payment Method
      </button>

      {/* Popup Message */}
      {updateMessage && (
        <div className="popup-message" style={{ marginTop: '20px', color: 'green', fontSize: '20px', padding: '10px', border: '1px solid green', borderRadius: '5px', backgroundColor: '#e0f7e0' }}>
          {updateMessage}
        </div>
      )}
    </div>
  );
};

export default AccountSettings;
