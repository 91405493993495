import React, { useEffect, useState } from 'react';
import './PaymentHistory.css';

const PaymentHistory = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = JSON.parse(localStorage.getItem('mysUser')).userId;
  const baseUrlAWS = process.env.REACT_APP_baseUrl;

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch(`${baseUrlAWS}api/users/get-user-payment-history/${userId}`);
        const data = await response.json();
        setPayments(data);
      } catch (error) {
        console.error('Error fetching payments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, []);

  return (
    <div className="payment-history-container">
      <h1>Payment History</h1>
      <div className="table-container">
        {loading ? (
          <p className="loading">Loading...</p>
        ) : payments.length > 0 ? (
          <table className="payments-table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Status</th>
                <th>Created</th>
                <th>Card Type</th>
                <th>Card Number</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.description}</td>
                  <td>{payment.amount.toFixed(2)}</td>
                  <td>{payment.currency.toUpperCase()}</td>
                  <td>{payment.status}</td>
                  <td>{new Date(payment.created).toLocaleString()}</td>
                  <td>{payment.card_type}</td>
                  <td>{`**** ${payment.card_number.slice(-4)}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="no-payments">No Payments Found</p>
        )}
      </div>
    </div>
  );
};

export default PaymentHistory;
