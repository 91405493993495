import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js"
import axios from 'axios';

let userPool;
const AWSbaseURL = process.env.REACT_APP_baseUrl
// For local test
//const AWSbaseURL = process.env.REACT_APP_localBaseUrlAWS
/*
const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId,
})
*/
async function initializeUserPool() {
  if (!userPool) {
    try {
      let baseURL = `${AWSbaseURL}api/users/`
      const response = await axios.get(baseURL + "cognito_config/");
      const config = response.data;

      // Set up the userPool with dynamically fetched values
      userPool = new CognitoUserPool({
        UserPoolId: config.userPoolId,
        ClientId: config.clientId,
      });

    } catch (error) {
      console.error('Failed to fetch Cognito config:', error);
      throw new Error('Failed to fetch Cognito config');
    }
  }
}
initializeUserPool();

// this function would create a welcome message in messageArea table for all users who verified their email account
export function createDefaultMessage(email) {
  console.log("createDefaultMessage called with email:", email);
  let baseURL = `${AWSbaseURL}api/message-area/messages/create`
  return axios.post(baseURL, {
    user: email,
    body: "Welcome to the Anti-aging Project! Subscribe for more advanced features!"
  })
}

export function updateUserMessageBody(user_id) {
  const baseURL = `${AWSbaseURL}api/message-area/messages/${user_id}/update-body`;
  return axios.put(baseURL)
    .then(response => {
      console.log("Message body updated:", response.data);
      return response.data;
    })
    .catch(error => {
      console.error("Error updating message body:", error);
    });
}

// This function checks users resetStatus 
export async function checkUserResetStatus(email) {
  let baseURL = `${AWSbaseURL}api/users/reset_password/${email}`
  return await axios.get(baseURL)
}

// This function change user resetStatus
export async function updateUserResetStatus(email, resetPasswordVal) {
  let baseURL = `${AWSbaseURL}api/users/reset_password/${email}`
  let currentCount = (await checkUserResetStatus(email)).data.resetPassword;
  if(resetPasswordVal === -1){
    // for reset resetPasswordVal
    return await axios.put(baseURL, {
      reset_password: 0,
    })
  }
  return await axios.put(baseURL, {
    reset_password: currentCount + resetPasswordVal,
  })
}

export function signUp(email, password, firstName, lastName, timezone) {
  return new Promise((resolve, reject) => {
    const attributeList = [{
        Name: "email",
        Value: email
      },
      {
        Name: "given_name",
        Value: firstName
      },
      {
        Name: "family_name",
        Value: lastName
      },
      {
        Name: "profile",
        Value: "yes"
      },
      {
        Name: "zoneinfo",
        Value: timezone
      }
    ];

    userPool.signUp(
      email,
      password,
      attributeList,
      null,
      (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result.user);
      }
    );
  });
}


export function confirmSignup(username, code) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err)
        return
      }
      resolve(result)
    })
  })
}
export function resendConfirmationCode(email) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
}

export function signIn(email, password) {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    })

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    })

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
        localStorage.setItem('idToken', session.getIdToken().getJwtToken());
        localStorage.setItem('refreshToken', session.getRefreshToken().getToken());
        resolve(session);
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export function forgotPassword(email) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    })

    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve()
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export function confirmPassword(email, confirmationCode, newPassword) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    })

    cognitoUser.confirmPassword(confirmationCode, newPassword, {
      onSuccess: () => {
        resolve()
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export function signOut() {
  // Sign out implementation
}

export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser()

    if (!cognitoUser) {
      reject(new Error("No user found"));
      return;
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
          return;
        }
        const userData = attributes.reduce((acc, attribute) => {
          acc[attribute.Name] = attribute.Value;
          return acc;
        }, {})

        resolve({
          ...userData,
          email: cognitoUser.username
        });
      })
    })
  })
}


export function getSession() {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error("No user found"))
      return
    }
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
        return
      }
      resolve(session)
    })
  })
}

