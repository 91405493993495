import React, { useState, useEffect } from "react";
import "./Footer.css";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop; // Current scroll position
      const windowHeight = window.innerHeight; // Viewport height
      const documentHeight = Math.max(
        document.documentElement.scrollHeight,
        document.body.scrollHeight
      ); // Ensure compatibility with various browsers

      // Adjusting threshold to avoid fractional issues
      if (scrollTop + windowHeight >= documentHeight - 5) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Dynamically add the tracking script
    const script = document.createElement("script");
    script.src = "https://js.adsrvr.org/up_loader.1.1.0.js";
    script.type = "text/javascript";
    script.async = true;

    script.onload = () => {
      if (typeof window.ttd_dom_ready === "function") {
        window.ttd_dom_ready(() => {
          if (typeof window.TTDUniversalPixelApi === "function") {
            const universalPixelApi = new window.TTDUniversalPixelApi();
            universalPixelApi.init(
              "w1qm34n",
              ["jomszlu"],
              "https://insight.adsrvr.org/track/up"
            );
          }
        });
      }
    };

    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={`footer ${isVisible ? "visible" : "hidden"}`}>
      {/* <div style={{ fontSize: "16px", fontWeight: "700" }}>Contact us</div> */}
      <div className="footer-content">
        <div>
          <span>
            For Information:{" "}
            <a href="mailto:info@myyouthspan.com">info@myyouthspan.com</a>
          </span>
          <span style={{ marginLeft: "20px" }}>
            For Application Support:{" "}
            <a href="mailto:support@myyouthspan.com">support@myyouthspan.com</a>
          </span>

          <span className="copyright">
            Copyright © METY Technology, Inc. 2024 - All Rights Reserved
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;